import { useRef } from "react";
import { Col, Row, Breadcrumb, Button, Image } from "antd";
import { Content } from "antd/es/layout/layout";
import CL from "../../assets/img/consultoria/diagnostico-asesoria-diseno-layout.png";
import CL1 from "../../assets/img/consultoria/diagnostico-asesorias-end-to-end-diagnostico.png";
import CL1M from "../../assets/img/consultoria/IMG_1_mobile.svg";
import CL2 from "../../assets/img/consultoria/diagnostico-asesorias-diseno-redes-distribucion.png";
import CL3 from "../../assets/img/consultoria/diagnostico-asesoria-dimensionamiento-de-flota.png";
import CL4 from "../../assets/img/consultoria/diagnostico-asesoria-calendarizacion-y-zonificacion.gif";
import CL5 from "../../assets/img/consultoria/diagnostico-asesoria-diseno-layout-2.png";
import CL6 from "../../assets/img/consultoria/diagnostico-asesoria-flujo-vehiculos.png";
import CL6M from "../../assets/img/consultoria/IMG_6_mobile.svg";
import P3 from "../../assets/img/Productos_3.png";
import FormImg from "../../assets/img/Formularios.png";
import PlannersForm from "../../components/Forms/PlannersForm";
import FeatureItem from "../../components/FeatureItem/FeatureItem";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../components/WhatsappIcon/WhatsappIcon";

const Consultoria = () => {
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Content style={{ paddingTop: "103px" }}>
      <Row>
        <Col lg={12} className="main-title-container">
          <Breadcrumb
            items={[
              {
                title: "Software",
              },
              {
                title: "Consultoría logística",
              },
            ]}
          />

          <div className="title-item">
            <h3 className="headline">Potencia tu Operación Logística</h3>
            <h3 className="headline">
              con The Optimal:{" "}
              <span
                className="headline"
                style={{
                  color: "#9825F2",
                }}
              >
                Eficiencia al
              </span>
            </h3>
            <h3
              className="headline"
              style={{
                color: "#9825F2",
              }}
            >
              Máximo Nivel.
            </h3>

            <p
              className="subtitle"
              style={{ marginTop: "35px", color: "#999" }}
            >
              Diagnóstico y consultoría expertos: Soluciones a la altura de tus
              necesidades y requerimientos.
            </p>
          </div>
          <div className="button-item">
            <Button className="buttonPrimary" onClick={scrollToForm}>
              Lo quiero
            </Button>
            <Link to="/contacto">
              <Button className="button-secondary-b">
                Habla con un experto
              </Button>
            </Link>
          </div>
        </Col>
        <Col lg={12} className="main-image-container">
          <Image src={CL} preview={false} alt="diagnostico-asesoria-diseno-layout.png" />
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div>
            <Link to="/contacto" className="contact-container">
              <Button className="buttonPrimary">Habla con un experto</Button>
            </Link>
            <WhatsappIcon />
          </div>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "125px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="co-image-container">
            <Image src={CL1} preview={false} alt="diagnostico-asesorias-end-to-end-diagnostico.png"/>
          </div>
          <div className="co-image-container-mobile">
            <Image src={CL1M} preview={false} />
          </div>
        </Col>
        <Col lg={12}>
          <div className="co-text-container">
            <h4 className="headline-sb">Diagnóstico End-to-end</h4>
            <div className="co-feature-container">
              <FeatureItem
                description={
                  "¿Crees que existen oportunidades de mejora en tu operación logística?"
                }
              />
              <FeatureItem
                description={
                  "¿No tienes certeza de cuáles son las causas raíces de estos problemas?"
                }
              />
            </div>
            <p className="pre-title-r">
              The Optimal responde estas interrogantes mediante un enfoque
              sistemático que permite diagnosticar la situación actual,
              contrastar la operación con un Benchmark y establecer un roadmap
              detallado de propuestas de mejora.
            </p>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          marginTop: "344px",
        }}
      >
        <Col lg={12}>
          <div className="co-text-container-two">
            <h4 className="headline-sb">Rediseño de redes de distribución</h4>
            <div className="co-feature-container-two">
              <FeatureItem
                description={
                  "¿Cuántos centros de distribución necesita mi red?"
                }
              />
              <FeatureItem description={"¿Dónde debieran estar ubicados?"} />
              <FeatureItem description={"¿Qué tamaños deberían tener?"} />
              <FeatureItem
                description={"¿Qué mix de productos deberían manejar?"}
              />
              <FeatureItem
                description={
                  "¿Cuáles deberían ser sus zonas de cobertura y promesas de entrega?"
                }
              />
            </div>
            <h3 className="pre-title-r">
              The Optimal resuelve estas preguntas de manera simultánea con
              modelos de optimización avanzados que te permitirán potenciar y
              optimizar los distintos canales de distribución de tu empresa.
            </h3>
          </div>
        </Col>
        <Col lg={12}>
          <div className="co-image-container-two">
            <Image src={CL2} preview={false} alt="diagnostico-asesorias-diseno-redes-distribucion.png" />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P3})`,
          backgroundPosition: "initial",
          marginTop: "66px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="co-image-container-three">
            <Image src={CL3} preview={false} alt="diagnostico-asesoria-dimensionamiento-de-flota.png" />
          </div>
        </Col>
        <Col lg={12}>
          <div className="co-text-container-three">
            <h4 className="headline-sb">Dimensionamiento de flota</h4>

            <div className="co-feature-container-three">
              <FeatureItem
                description={
                  "¿Cuál es el tamaño óptimo de mi operación en distintos momentos del año?"
                }
              />
              <FeatureItem
                description={
                  "¿Qué tipos de camiones y de qué tamaño se requieren?"
                }
              />
            </div>
            <p className="pre-title-r">
              Con el dimensionamiento de flota te ayudaremos a seleccionar el
              tipo y cantidad de vehículos que necesitas para cumplir con las
              exigencias de demanda para los despachos del centro de
              distribución
            </p>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          marginTop: "346px",
        }}
      >
        <Col lg={12}>
          <div className="co-text-container-four">
            <h4 className="headline-sb">
              Calendarización y zonificación eficiente
            </h4>

            <div className="co-feature-container-four">
              <FeatureItem
                description={
                  "¿Mi operación tiene capacidad ociosa algunos días en la semana?"
                }
              />
              <FeatureItem
                description={"¿Hay otros días con peaks muy marcados?"}
              />
            </div>
            <p className="pre-title-r">
              The Optimal crea calendarios de entrega de tal manera de
              aprovechar al máximo la capacidad instalada y brindar un nivel de
              servicio óptimo.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="co-image-container-four">
            <Image src={CL4} preview={false} alt="diagnostico-asesoria-calendarizacion-y-zonificacion.png" />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P3})`,
          backgroundPosition: "initial",
          marginTop: "66px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="co-image-container-three">
            <Image src={CL5} preview={false} alt="diagnostico-asesoria-diseno-layout.png" />
          </div>
        </Col>
        <Col lg={12}>
          <div className="co-text-container-five">
            <h4 className="headline-sb">Diseño de layouts</h4>
            <p className="pre-title-r">
              Tanto para centros de distribución existentes o en etapa de diseño
              conceptual, The Optimal define esquemas de layout eficientes,
              optimiza procesos a partir de las mejores prácticas del mundo
              logístico, recomienda equipamiento idóneo para la operación, y
              evalúa la utilización de software y hardware que modernice la
              operación, entre otros.
            </p>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          marginTop: "167px",
        }}
      >
        <Col lg={12}>
          <div className="co-text-container-six">
            <h4 className="headline-sb">Flujos vehiculares</h4>
            <div className="co-feature-container-five">
              <FeatureItem
                description={
                  "¿Por qué los tiempos de estadía de camiones en mi planta productiva son tan altos?"
                }
              />
              <FeatureItem
                description={"¿Cómo puedo mejorar esta situación?"}
              />
            </div>
            <p className="pre-title-r">
              A través de herramientas de microsimulación, The Optimal detecta
              cuellos de botella en la atención de vehículos, evalúa distintos
              escenarios de operación y define medidas a implementar con un
              análisis costo-beneficio detallado.
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="co-image-container-five">
            <Image src={CL6} preview={false} alt="diagnostico-asesoria-flujo-vehiculos.png"/>
          </div>
          <div className="co-image-container-five-mobile">
            <Image src={CL6M} preview={false} />
          </div>
        </Col>
      </Row>
      <Row
        style={{
          background: "#FAF9FF",
        }}
      >
        <Col lg={24}>
          <div className="banner-co">
            <div className="banner-title">
              <h3 className="headline">
                ¡Optimiza tu operación logística con The Optimal!
              </h3>
              <h3 className="headline" style={{ color: "#9825F2" }}>
                Solicita asesoría ahora y potencia tu éxito logístico
                <span style={{ color: "#161227" }}>
                  {" "}
                  con nuestros expertos.
                </span>
              </h3>
            </div>
            <div className="banner-button-co">
              <Button className="buttonPrimary" onClick={scrollToForm}>
                Solicitar consultoría
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <div ref={formRef} />
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${FormImg})`,
        }}
        justify={"center"}
      >
        <div className="form-container-pri">
          <PlannersForm title={"Consultoría logística"} />
        </div>
      </Row>
    </Content>
  );
};

export default Consultoria;
