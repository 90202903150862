import { useRef } from "react";
import { Content } from "antd/es/layout/layout";
import { Col, Row, Breadcrumb, Button, Image } from "antd";
import PlannersForm from "../../components/Forms/PlannersForm";
import Principal from "../../assets/img/Principal.png";
import P2 from "../../assets/img/Productos_2.png";
import P3 from "../../assets/img/Productos_3.png";
import P4 from "../../assets/img/Home_4.png";
import FormImg from "../../assets/img/Formularios.png";
import PP1 from "../../assets/img/planificacion-transporte-pasajeros.gif";
import PP2 from "../../assets/img/monitoreo-gestión-tiemporal-pasajeros.gif";
import PP3 from "../../assets/img/notificacion-transporte-pasajeros.gif";
import FeatureItem from "../../components/FeatureItem/FeatureItem";
import Banner from "../../assets/img/Group_2491.png";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../components/WhatsappIcon/WhatsappIcon";

const KitPlannersPasajeros = () => {
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Content style={{ paddingTop: "103px" }}>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${Principal})`,
          height: "750px",
        }}
      >
        <Col lg={12} className="main-title-container">
          <Breadcrumb
            items={[
              {
                title: "Software",
              },
              {
                title: "Kit Planners Pasajeros",
              },
            ]}
          />

          <div className="title-item">
            <h3
              className="headline"
              style={{
                color: "#9825F2",
              }}
            >
              Transporte de Pasajeros
            </h3>
            <h3 className="headline">
              Optimizado: Eficiencia y Confort en Cada Viaje
            </h3>

            <p
              className="subtitle"
              style={{ marginTop: "35px", color: "#999" }}
            >
              Descubre nuestra plataforma especializada en transporte de
              pasajeros. Planifica, monitorea y notifica rutas. ¡Mejora la
              experiencia de transporte con nosotros!
            </p>
          </div>
          <div className="button-item">
            <Button className="buttonPrimary" onClick={scrollToForm}>
              Lo quiero
            </Button>
            <Link to="/contacto">
              <Button className="button-secondary-b">
                Habla con un experto
              </Button>
            </Link>
          </div>
        </Col>
        <Col lg={12}>
          <div className="main-image-container">
            <Image
              src={PP2}
              alt="monitoreo-gestion-temporal-pasajeros.gif"
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div>
            <Link to="/contacto" className="contact-container">
              <Button className="buttonPrimary">Habla con un experto</Button>
            </Link>
            <WhatsappIcon />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} className="main-manage-title">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <h3 className="headline">
              Garantiza traslados de pasajeros{" "}
              <span style={{ color: "#9825F2" }}>
                {" "}
                seguros, puntuales y rentables
              </span>
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "45px",
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          marginTop: "89px",
          backgroundImage: `url(${P4})`,
          backgroundPosition: "revert",
        }}
      >
        <Col lg={12}>
          <div className="planner-text-container">
            <h4 className="headline-sb" style={{ fontWeight: "600" }}>
              Planificación de Transporte: Optimiza{" "}
              <span style={{ color: "#9825F2" }}>Movimientos de Pasajeros</span>
            </h4>
            <div className="planners-feature-item">
              <FeatureItem
                description={
                  "Planificación en base a roles y turnos de funcionarios."
                }
              />
              <FeatureItem
                description={
                  "Gestión de traslado para ingreso y salida de personal."
                }
              />
              <FeatureItem
                description={
                  "Ingreso de vacaciones, licencias médicas y ausentismo."
                }
              />
              <FeatureItem
                description={
                  "Caracterización de móviles según color y patente de vehículo."
                }
              />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="planners-image-container">
            <Image
              src={PP1}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="planificacion-transporte-pasajeros.gif"
            />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P2})`,
          marginTop: "85px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="planners-image-container-two">
            <Image
              src={PP2}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="monitoreo-gestión-tiemporal-pasajeros.gif"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="planners-text-container-two">
            <h4
              className="headline-sb"
              style={{ fontWeight: "600", color: "#9825F2" }}
            >
              Monitorea{" "}
              <span style={{ color: "#000" }}>
                y gestiona en tiempo real la ejecución de
              </span>{" "}
              tus rutas
            </h4>
            <div className="planners-feature-item-two">
              <FeatureItem
                description={
                  "Control de llegada y puntualidad de vehículos a destino."
                }
              />
              <FeatureItem description={"Mapa de seguimiento en vivo."} />
              <FeatureItem description={"Edición de rutas en curso."} />
              <FeatureItem
                description={
                  "Reportería estándar en excel y a medida con herramientas BI (Tableau)"
                }
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P3})`,
          backgroundPosition: "initial",
        }}
      >
        <Col lg={12}>
          <div className="planners-text-container-three">
            <h4
              className="headline-sb"
              style={{
                fontWeight: "600",
              }}
            >
              Notifica y{" "}
              <span style={{ color: "#9825F2" }}>
                mantén a tus pasajeros informados y satisfechos
              </span>{" "}
              de su viaje.
            </h4>
            <div className="planners-feature-item-three">
              <FeatureItem
                description={
                  "Notificación a tus pasajero vía SMS, portal web de consultas, o mail con diseño corporativo."
                }
              />
              <FeatureItem
                description={
                  "Link de seguimiento corporativo para notificar según estado de planificación."
                }
              />
              <FeatureItem description={"Edición de rutas en curso."} />
              <FeatureItem
                description={
                  "Aplicación móvil clientes On My Way (con opción de diseño corporativo)"
                }
              />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="planners-image-container-three">
            <Image
              src={PP3}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              /*               width={"328px"}
              height={"710px"} */
              alt="notificacion-transporte-pasajeros.gif"
            />
          </div>
        </Col>
      </Row>
      <Row className="row-banner">
        <Col lg={14}>
          <div className="banner-pla">
            <h3 className="headline">
              ¡Eleva tu capacidad
              <span style={{ color: "#9825F2" }}> logística de pasajeros </span>
              con nuestro servicio especializado!
            </h3>

            <div className="banner-button-pla">
              <Button className="buttonPrimary" onClick={scrollToForm}>
                Lo quiero
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={10}>
          <div className="banner-image-pla">
            <Image src={Banner} preview={false} style={{}} />
          </div>
        </Col>
      </Row>
      <div ref={formRef} />
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${FormImg})`,
        }}
        justify={"center"}
      >
        <div className="form-container-pri">
          <PlannersForm title={"Planners pasajero"} />
        </div>
      </Row>
    </Content>
  );
};

export default KitPlannersPasajeros;
