import React from "react";
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";

const { Content } = Layout;

const Terminos = () => {
  return (
    <Content className="term-container">
      <Row>
        <Col xs={24} lg={24}>
          <div style={{ textAlign: "center" }}>
            <h3 className="headline">
              Términos y
              <span
                className="headline"
                style={{
                  color: "#9825F2",
                }}
              >
                {" "}
                condiciones
              </span>
            </h3>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "126px" }}>
        <Col lg={24}>
          <h2 className="subtitle-medium">
            PRIMERO: Uso de softwares, plataformas, aplicaciones móviles y todos
            los servicios relacionados:
          </h2>

          <ol style={{ paddingTop: "34px" }}>
            <li className="pre-title-r">
              Los Servicios comprometidos serán únicamente accesibles por parte
              del CLIENTE y/o empresas debidamente autorizadas, para los cuales
              THE OPTIMAL haya asignado y facilitado la correspondiente
              Contraseña o Password Software as a Service, el cuál es entregado
              al cliente como un SERVICIO, conforme a lo previsto previamente.
            </li>
            <li className="pre-title-r">
              Los servicios vigentes de THE OPTIMAL hacen referencia a las
              plataformas de acceso por medio de navegador web LLEGANDO y
              ROUTING PLANNERS, junto con las plataformas de acceso por medio de
              aplicaciones para dispositivos móviles <Link to="/politicas-de-privacidad" style={{color: '#9825F2'}}>ROUTING CONDUCTORES, ON MY
              WAY, TO BUSES CONDUCTORES, THE OPTIMAL SIR y ROUTING MOBILE</Link> las
              que se encuentran disponibles en las tiendas de aplicaciones
              Playstore y Appstore
            </li>
            <li className="pre-title-r">
              El CLIENTE se abstendrá de realizar copias, revelar y permitir el
              acceso de terceros a los Servicios ofrecidos, salvo autorización
              expresa y previa de THE OPTIMAL para terceros debidamente
              identificados. El CLIENTE deberá pactar las mismas condiciones de
              confidencialidad del presente contrato con las empresas,
              proveedores o personas naturales que tengan acceso a los
              Servicios.
            </li>
            <li className="pre-title-r">
              El CLIENTE únicamente podrá utilizar los Servicios de conformidad
              con las finalidades previstas en los presentes Términos y
              Condiciones. A título enunciativo y no limitativo, se prohíbe
              expresamente al CLIENTE la utilización de los Servicios para las
              siguientes actividades:
            </li>
            <li className="pre-title-r">
              La explotación, reproducción, difusión, transformación,
              distribución, transmisión por cualquier medio, posterior
              publicación, exhibición, comunicación pública o representación
              total o parcial de los Servicios.
            </li>
            <li className="pre-title-r">
              Permitir el uso o acceso  a los Servicios a personas distintas al
              CLIENTE y al número de usuarios contratado por el CLIENTE. Por lo
              tanto, el CLIENTE es responsable de mantener la confidencialidad
              de su cuenta y de cualquier actividad bajo su nombre de usuario y
              contraseña. 
            </li>
            <li className="pre-title-r">
              Realizar cualquier tipo de actuación que pueda comportar
              modificaciones, ajustes, daños o alteraciones a los Servicios.
            </li>
            <li className="pre-title-r">
              Tratar información o datos que tengan la consideración de
              ilícitos, ofensivos, calumniosos, injuriosos, difamatorios,
              racistas, xenófobos, obscenos, insultantes, amenazantes o
              discriminatorios.
            </li>
            <li className="pre-title-r">
              Tratar información o datos que contengan virus, troyanos, o
              cualesquiera otros elementos que puedan producir daños o
              alteraciones en los Servicios.
            </li>
            <li className="pre-title-r">
              En general, cualquier tipo de actividad ofensiva contra el orden
              público, la moral y las leyes.
            </li>
            <li className="pre-title-r">
              Haciendo uso de los Servicios, el CLIENTE se compromete a no
              transmitir, difundir o poner a disposición de otros Clientes o de
              terceras informaciones, mensajes, gráficos, archivos de sonido y/o
              imagen, fotografías, grabaciones, códigos fuente y en general
              cualquier clase de material, datos o contenidos (en adelante, “los
              Contenidos”) que:
            </li>
          </ol>
          <ul>
            <li className="pre-title-r">
              Induzcan, inciten o promuevan actuaciones delictivas,
              denigratorias, difamatorias, infamantes, violentas o, en general,
              contrarias a la ley, a la moral y buenas costumbres generalmente
              aceptadas o al orden público.
            </li>
            <li className="pre-title-r">
              Induzcan, inciten o promuevan actuaciones, actitudes o ideas
              discriminatorias por razón de sexo, raza, religión, creencias,
              edad o condición.
            </li>
            <li className="pre-title-r">
              Incorporen mensajes delictivos, violentos, pornográficos,
              degradantes o, en general contrarios a la ley, a la moral y buenas
              costumbres generalmente aceptadas o al orden público.
            </li>
            <li className="pre-title-r">
              Se encuentren protegidos por cualesquiera derechos de propiedad
              intelectual o industrial pertenecientes a terceros, sin que el
              CLIENTE haya obtenido previamente de sus titulares la autorización
              necesaria para llevar a cabo el uso que efectúa o pretende
              efectuar.
            </li>
            <li className="pre-title-r">
              Constituyan publicidad ilícita, engañosa o desleal y, en general,
              que constituyan competencia desleal.
            </li>
            <li className="pre-title-r">
              Provoquen, por sus características (tales como formato, extensión,
              etc), dificultades en el normal funcionamiento de los Servicios.
            </li>
          </ul>
          <br />
          <p className="pre-title-r">
            El CLIENTE responderá de cualesquiera daños y perjuicios directos y
            efectivos, que provengan de su hecho o culpa, y que THE OPTIMAL
            sufra como consecuencia del incumplimiento por el propio CLIENTE de
            cualesquiera obligaciones asumidas en relación con los Contenidos, y
            mantendrá indemne a THE OPTIMAL frente a cualquier reclamación que
            le pueda ser interpuesta al respecto de lo anterior, aceptando pagar
            las cantidades a las que por esos conceptos THE OPTIMAL se vea
            obligado a satisfacer. A fin de verificar el cumplimiento por el
            CLIENTE de lo previsto en los Términos y Condiciones  y garantizar
            la seguridad y buena utilización de los Servicios, y al objeto de
            asegurar un mejor rendimiento o ajuste de determinados elementos de
            los Servicios, THE OPTIMAL se reserva el derecho de monitorizar el
            uso que el CLIENTE realice a los Servicios. 
          </p>
          <br />
          <p className="pre-title-r">
            El CLIENTE es el único responsable del contenido de sus
            transmisiones al utilizar los Servicios, así como de la actividad
            que se produzca en los equipos asociados al CLIENTE. De igual modo,
            en caso de que el CLIENTE facilitara el acceso programado de
            terceros (invitaciones) a sus equipos, será el único responsable de
            las actuaciones que se realicen en los mismos. A dicho respecto, THE
            OPTIMAL podrá facilitar al CLIENTE los medios necesarios para que
            éste pueda verificar las acciones realizadas, así como un mecanismo
            que advierte sobre los controles remotos a los que son sometidos los
            Equipos. El CLIENTE se compromete a mantener en secreto sus
            contraseñas. El CLIENTE conviene notificar inmediatamente a THE
            OPTIMAL acerca de cualquier uso no autorizado de la cuenta asociada
            al CLIENTE.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "120px" }}>
        <Col>
          <h2 className="subtitle-medium">SEGUNDO: Propiedad intelectual</h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            Todos los Servicios contratados por el CLIENTE han sido creado por
            THE OPTIMAL, quien conservará todos los derechos de propiedad
            intelectual industrial o cualesquiera otros sobre los Servicios, que
            no podrá ser objeto de ulterior modificación, copia, alteración,
            reproducción, adaptación o traducción por parte del CLIENTE.
            Incluyendo,estructura, características, códigos, métodos de trabajo,
            sistemas de información, herramientas de desarrollo, know-how,
            metodologías, procesos,  diseños, logotipos, marcas comerciales,
            contenido, tecnologías, algoritmos de los Servicios contratados son
            propiedad de THE OPTIMAL, y están protegidos por las normas chilenas
            de propiedad intelectual, y no pueden ser objeto de ulterior
            modificación, copia, alteración, reproducción, adaptación o
            traducción por parte del CLIENTE. Asimismo, todos los manuales de
            uso, textos, dibujos gráficos, bases de datos, videos o soportes de
            audio referidos o que complementan los Servicios (en adelante los
            “Materiales Asociados”) son propiedad de THE OPTIMAL , o de sus
            proveedores de contenidos, y no pueden ser objeto de ulterior
            modificación, copia, alteración, reproducción, adaptación o
            traducción por parte del CLIENTE. La puesta a disposición del
            CLIENTE de los Servicios y de los Materiales Asociados no implica,
            en ningún caso, la cesión de su titularidad ni la concesión de un
            derecho de uso en favor del CLIENTE distinto del previsto en el
            presente Contrato. En consecuencia, queda terminantemente prohibido
            cualquier uso por el CLIENTE de los Servicios o de los Materiales
            Asociados que se realice sin la autorización de THE OPTIMAL,
            incluida su explotación, reproducción, difusión, transformación,
            distribución, transmisión por cualquier medio, posterior
            publicación, exhibición, comunicación pública o representación total
            o parcial, las cuales, de producirse, constituirán infracciones de
            los derechos de propiedad intelectual de THE OPTIMAL , sancionadas
            por la legislación vigente.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "80px" }}>
        <Col>
          <h2 className="subtitle-medium">
            TERCERO: Privacidad y protección de datos
          </h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            En relación con los datos de carácter personal que el CLIENTE
            proporcione a THE OPTIMAL, serán de aplicación las siguientes
            prevenciones:
          </p>
          <br />
          <p className="pre-title-r">
            THE OPTIMAL informa al CLIENTE que los datos de carácter personal
            que proporcione mediante el cumplimiento de estos términos y
            condiciones serán recogidos en una base de datos cuyo responsable es
            THE OPTIMAL. El CLIENTE podrá ejercer sus derechos de acceso,
            rectificación, cancelación y oposición al tratamiento de dichos
            datos. THE OPTIMAL almacenará en una base de datos operativa (de uso
            diario) 12 meses de historia. Transcurrido ese tiempo, la
            información se trasladará a una base de datos histórica, de la cual
            se podrán extraer archivos planos, previa solicitud del CLIENTE. Una
            vez realizada la solicitud, THE OPTIMAL entregará la información en
            a lo más dos semanas (10 días hábiles). El volumen de almacenamiento
            de la base de datos o el límite de información cargada o
            georreferenciada deberá respetar un máximo de carga a fin de evitar
            la sobrecarga de la plataforma de THE OPTIMAL. Con el propósito de
            evitar esa saturación, las Partes convienen en establecer como
            límite de datos cargados en la base de datos el siguiente: 50.000
            perfiles.
          </p>
          <br />
          <p className="pre-title-r">
            La entrega imparcial, incorrecta o no fidedigna de los datos de
            carácter personal que se solicitan al CLIENTE podría suponer que THE
            OPTIMAL no pueda atender correctamente sus necesidades. THE OPTIMAL
            informa que tiene instaladas las medidas de seguridad de índole
            técnica y organizativas necesarias para garantizar la seguridad de
            los datos de carácter personal del CLIENTE y eviten su alteración,
            pérdida, tratamiento y/o acceso no autorizado, habida cuenta del
            estado de la tecnología, la naturaleza de los datos almacenados y
            los riesgos a que están expuestos, ya provengan de la acción humana
            o del medio físico o natural. Durante la prestación de los
            Servicios, excepcionalmente THE OPTIMAL podría verse obligado a
            tener acceso, por razones de mantenimiento o de seguridad, a datos
            de carácter personal que sean titularidad del CLIENTE, en cuyo caso
            serán de aplicación las siguientes previsiones: THE OPTIMAL y el
            CLIENTE cumplirán adecuadamente y en todo momento las disposiciones
            contenidas en la normativa de protección de datos que sea aplicable
            con respecto de las informaciones y datos manejados en el curso de
            la prestación de los Servicios objeto del presente Contrato.
          </p>
          <br />
          <p className="pre-title-r">
            En particular, THE OPTIMAL se compromete a tratar los datos conforme
            a las instrucciones del CLIENTE, a no aplicarlos con fin distinto al
            de la prestación de los servicios objeto del presente Contrato y a
            no comunicarlos, ni siquiera para su conservación, a otras personas.
            Una vez finalizada la prestación de los servicios, los datos de
            carácter personal deberán ser destruidos o devueltos al CLIENTE, a
            elección de éste, al igual que cualquier soporte o documento en que
            conste algún dato de carácter personal objeto de tratamiento.
          </p>
          <br />
          <p className="pre-title-r">
            El CLIENTE cumplirá asimismo las obligaciones contenidas en las
            disposiciones normativas sobre protección de datos personales que le
            atañen, las obligaciones legales frente a los afectados y la
            autoridad competente en materia de protección de datos de carácter
            personal u organismo que la sustituya, o, en su caso, las
            autoridades competentes en la materia.
          </p>
          <br />
          <p className="pre-title-r">
            En relación con lo dispuesto previamente, el CLIENTE autoriza
            expresamente que THE OPTIMAL contrate efectivamente y pueda
            subcontratar a las empresas proveedoras de servicios en red para que
            presten determinados servicios que THE OPTIMAL precisa para la
            prestación de los Servicios al CLIENTE. Como quiera que la
            prestación de dichos servicios puede requerir en casos excepcionales
            que las referidas empresas traten datos de carácter personal
            propiedad del CLIENTE.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "80px" }}>
        <Col>
          <h2 className="subtitle-medium">
            CUARTO: Limitación de responsabilidad
          </h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            THE OPTIMAL será el único y exclusivo responsable frente al CLIENTE
            del cumplimiento e incumplimiento de sus obligaciones establecidas
            en estos Términos y Condiciones, aún en el caso de que dichas
            obligaciones hayan sido incumplidas por los contratistas de THE
            OPTIMAL. Sin embargo, esa responsabilidad de THE OPTIMAL estará
            sujeta a las siguientes limitaciones:
          </p>

          <p className="pre-title-r">
            La responsabilidad total de THE OPTIMAL por todos los conceptos
            derivados de la prestación de los Servicios , incluyendo la licencia
            de uso, no podrá exceder en ningún caso de una cantidad equivalente
            al precio pactado con THE OPTIMAL. En ningún caso podrá el CLIENTE
            reclamar de THE OPTIMAL indemnización alguna por daños o perjuicios
            que puedan ser calificados como daños indirectos, consecuenciales,
            morales, derivados, lucro cesante, pérdida de negocios, pérdida de
            reputación comercial o reclamaciones de terceros con el CLIENTE ni
            por cualesquiera daños similares.
          </p>
          <p className="pre-title-r">
            Con relación a incumplimientos producidos en el desarrollo de
            funciones o servicios que, al amparo de lo dispuesto en la cláusula
            precedentemente hayan sido prestados por terceros subcontratados por
            THE OPTIMAL, la responsabilidad total de THE OPTIMAL no excederá, en
            su conjunto, del monto del presente Contrato.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "80px" }}>
        <Col>
          <h2 className="subtitle-medium">
            QUINTO: Garantías sobre los Servicios contratados:
          </h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            En particular, el CLIENTE acepta que los Servicios se suministran
            “tal y como se presentan” (“as it is”), por lo que THE OPTIMAL no
            garantiza en ningún caso que los Servicios satisfagan las
            necesidades del CLIENTE, ni que los Servicios funcionen
            ininterrumpidamente o sin errores. El CLIENTE asumirá el costo
            íntegro de todos los servicios, y correcciones necesarias para
            adaptarlo a sus necesidades.
          </p>

          <p className="pre-title-r">
            El CLIENTE acepta que los Servicios contratados constituyen una
            herramienta de trabajo destinada a complementar, pero no a
            sustituir, la labor humana. Para hacer uso de la licencia concedida
            sobre el Servicio el CLIENTE deberá cumplir en todo momento, entre
            otras, con las obligaciones de: (i) realizar copias de seguridad de
            los archivos de datos generados con el uso continuado de los
            Servicios contratado; (ii) utilizar el Servicio conforme a las
            estipulaciones previstas por la THE OPTIMAL; (iii) disponer de un
            sistema informativo en buen estado; (iv) disponer de la instalación
            de un sistema de alimentación ininterrumpida con estabilizador de
            tensión; (v) realizar un control del acceso al SaaS con el fin de
            evitar manipulación por personas no autorizadas o inexpertas; (vi)
            tener contratados servicios de mantenimientos periódico de hardware
            y software; (vii) no permitir la instalación de virus y programas
            defectuosos en el mismo disco duro; (viii) así como cualquier otra
            medida preventiva de razonable aplicación. En ningún caso THE
            OPTIMAL será responsable de los errores de funcionamiento o de los
            daños provocados por el incumplimiento de las obligaciones del
            CLIENTE referidas previamente o de cualquier obligación que le sean
            de aplicación de conformidad con lo provisto en este Contrato.
          </p>
          <p className="pre-title-r">
            THE OPTIMAL tampoco será responsable por cualesquiera daños o
            perjuicios que puedan ser calificados como lucro cesante, pérdida de
            negocios, daños a la imagen o pérdida de reputación comercial.
          </p>
          <p className="pre-title-r">
            En ningún caso podrá el CLIENTE reclamar indemnización alguna por
            daños o perjuicios producidos por causas fuera de control de THE
            OPTIMAL, y, en particular, por la interrupción o fallos de cualquier
            tipo producidos (i) en los sistemas de alimentación; (ii) en las
            redes de telecomunicaciones; (iii) en los elementos de
            interconexión; así como (iv) en los equipos y terminales del
            CLIENTE.
          </p>
          <p className="pre-title-r">
            La responsabilidad de THE OPTIMAL por los daños imputables
            directamente al uso del SaaS no podrá exceder en ningún caso de la
            cantidad efectivamente percibida por THE OPTIMAL en concepto de
            contraprestación por la licencia de uso del SaaS.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "80px" }}>
        <Col>
          <h2 className="subtitle-medium">SEXTO: Políticas de Privacidad</h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            Esta Política de Privacidad describe cómo recopilamos, utilizamos y
            protegemos la información personal que el CLIENTE nos proporciona
            cuando utiliza nuestros servicios y visita nuestro sitio web de
            acuerdo con la ley chilena.
          </p>
          <br />
          <p className="pre-title-r">
            La información personal que recopilamos será utilizada
            exclusivamente para fines relacionados con los servicios que ofrece
            THE OPTIMAL. Por lo tanto, el CLIENTE acuerda que THE OPTIMAL no
            podrá utilizar ni compartir su información personal con terceros
            para fines distintos a los servicios prestados por THE OPTIMAL, a
            menos que el CLIENTE exprese su consentimiento escrito o THE OPTIMAL
            esté legalmente obligado hacerlo.
          </p>
          <br />
          <p className="pre-title-r">Uso de la información:</p> <br />
          <p className="pre-title-r">
            a. THE OPTIMAL utiliza la información personal a la que tiene acceso
            con el fin de brindar un excelente servicio y mejorar su experiencia
            de usuario.
          </p>
          <p className="pre-title-r">
            b. Dicha información se podrá usar para fines estadísticos, análisis
            de datos, mejora de nuestros servicios y personalización del
            contenido que se muestra.
          </p>
          <br />
          <p className="pre-title-r">Compartir información:</p> <br />
          <p className="pre-title-r">
            a. THE OPTIMAL No vende ni comparte información personal de CLIENTES
            con terceros para fines de marketing sin su consentimiento previo.
          </p>
          <p className="pre-title-r">
            b. THE OPTIMAL podrá compartir información personal con proveedores
            de servicios de confianza que nos asisten en la operación de nuestro
            negocio y la prestación de nuestros servicios, siempre y cuando
            estén sujetos a obligaciones de confidencialidad.
          </p>
          <br />
          <p className="pre-title-r">Seguridad de la información:</p> <br />
          <p className="pre-title-r">
            a. THE OPTIMAL, tomará todas las medidas razonables para proteger la
            información personal que recopila y almacena.
          </p>
          <p className="pre-title-r">
            b. Sin embargo, el CLIENTE tendrá en cuenta que la  transmisión de
            datos por Internet o almacenamiento electrónico no dependen THE
            OPTIMAL y no podemos garantizar la seguridad absoluta de su
            información.
          </p>
          <br />
          <p className="pre-title-r">Sus derechos:</p> <br />
          <p className="pre-title-r">
            a. El CLIENTE tiene derecho a acceder, rectificar, cancelar y
            oponerse al tratamiento de su información personal.
          </p>
          <p className="pre-title-r">
            b. Si desea ejercer estos derechos, puede comunicarse con nosotros a
            través de contacto@theoptimalpartner.com
          </p>
          <br />
          <p className="pre-title-r">Cookies:</p> <br />
          <p className="pre-title-r">
            a. Utilizamos cookies y tecnologías similares para mejorar su
            experiencia de usuario y recopilar información sobre el uso de
            nuestro sitio web.
          </p>
          <p className="pre-title-r">
            b. Puede configurar su navegador para que rechace todas las cookies
            o para que le informe cuando se envía una cookie. Sin embargo, esto
            puede afectar la funcionalidad de nuestro sitio web.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "80px" }}>
        <Col>
          <h2 className="subtitle-medium">
            SÉPTIMO: Ley Aplicable y Jurisdicción
          </h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            Para todos los efectos legales las partes fijan su domicilio en la
            ciudad de Santiago, Región Metropolitana, Chile. Los términos y
            condiciones se regularán e interpretará de acuerdo con la
            legislación chilena.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "80px" }}>
        <Col>
          <h2 className="subtitle-medium">OCTAVO: Resolución Disputas</h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            Cualquier dificultad o controversia que se produzca entre el CLIENTE
            respecto de la aplicación, interpretación, duración, validez o
            ejecución de estos términos y condiciones o cualquier otro motivo
            será sometida a Arbitraje, conforme al Reglamento Procesal de
            Arbitraje vigente del Centro de Arbitraje y Mediación de Santiago.
            El árbitro será elegido de común acuerdo entre las Partes, y a falta
            de acuerdo entre ellas, por el Centro de Arbitrajes y Mediación de
            la Cámara de Comercio de Santiago, para lo cual las partes confieren
            poder especial irrevocable a la Cámara de Comercio de Santiago A.G.,
            para que, a solicitud escrita de cualquiera de ellas, designe al
            árbitro mixto – arbitrador en cuanto al procedimiento y de derecho
            en cuanto al fallo – de entre los abogados integrantes del cuerpo
            arbitral del Centro de Arbitraje y Mediación de Santiago. La sola
            circunstancia que una de las Partes requiera la designación de un
            árbitro a la Cámara de Comercio de Santiago, implicará la falta de
            acuerdo en su designación. El árbitro queda especialmente facultado
            para resolver todo asunto relacionado con su competencia y/o
            jurisdicción. El árbitro podrá decidir cómo se repartirán el costo
            del arbitraje las partes.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "80px", marginBottom: "48px" }}>
        <Col>
          <h2 className="subtitle-medium">
            NOVENO: Modificaciones y terminación:
          </h2>
          <p className="pre-title-r" style={{ paddingTop: "34px" }}>
            THE OPTIMAL se reserva el derecho de actualizar estos Términos y
            Condiciones en cualquier momento. Cualquier cambio será publicado en
            nuestro sitio web TheOptimal.com con una indicación clara de la
            fecha de entrada en vigencia. Dicha fecha será razonable y permitirá
            al CLIENTE suficiente tiempo para revisar y adherirse a los nuevos
            términos y condiciones.
          </p>
          <br />
          <p className="pre-title-r">
            b. El CLIENTE tiene la responsabilidad de revisar periódicamente
            estos Términos y Condiciones para mantenerse informado sobre
            nuestras Políticas más recientes. En caso de que el CLIENTE no esté
            de acuerdo con los términos y condiciones actualizados, deberá
            notificar su objeción por escrito a través de
            contacto@theoptimalpartner.com con al menos 30 días de anticipación
            antes de la fecha de entrada en vigencia de los cambios. Si el
            CLIENTE no notifica su objeción en el plazo especificado, se
            entenderá que ha aceptado y está de acuerdo con los términos y
            condiciones actualizados.
          </p>
          <br />
          <p className="pre-title-r">
            Si el CLIENTE tiene alguna inquietud o pregunta sobre nuestros
            Términos y Condiciones, deberá ponerse en contacto con nosotros a
            través de contacto@theoptimalpartner.com.
          </p>
        </Col>
      </Row>
    </Content>
  );
};

export default Terminos;
