import React from "react";
import { Card } from "antd";
import { Icon } from "@iconify/react";

const AboutUsCard = ({ icon, title, description }) => {
  return (
    <Card>
      <div className="icon_container">
        <Icon icon={icon} width={"48px"} />
      </div>
      <p
        className="headline-sb"
        style={{
          lineHeight: "150%",
          paddingTop: "14px",
        }}
      >
        {title}
      </p>
      <div className="description_container">
        <p
          className="pre-title-r"
          style={{
            lineHeight: "150%",
            textAlign: "initial",
          }}
        >
          {description}
        </p>
      </div>
    </Card>
  );
};

export default AboutUsCard;
