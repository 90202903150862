import React from "react";
import { Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Card>
      <div className="feature_icon_container">
        <FontAwesomeIcon
          icon={icon}
          style={{ color: "#9825F2", height: "39px" }}
          size="2xl"
        />
      </div>
      <div className="feature_title_container">
        <p className="headline-sb" style={{ lineHeight: "38px" }}>
          {title}
        </p>
      </div>

      <div className="feature_desc_container">
        <p
          className="pre-title"
          style={{ color: "#717171", lineHeight: "38px" }}
        >
          {description}
        </p>
      </div>
    </Card>
  );
};

export default FeatureCard;
