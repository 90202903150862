import { useRef } from "react";
import { Col, Row, Breadcrumb, Button, Image } from "antd";
import { Content } from "antd/es/layout/layout";
import Principal from "../../assets/img/Principal.png";
import P4 from "../../assets/img/Home_4.png";
import P2 from "../../assets/img/Productos_2.png";
import P3 from "../../assets/img/Productos_3.png";
import INV3 from "../../assets/img/modelo-predictivo-quiebre-inventario.gif";
import INV from "../../assets/img/demanda-stock-inventario.gif";
import INV2 from "../../assets/img/inventario-pronostico-sobrestock.gif";
import INV4 from "../../assets/img/evaluacion-inventario.gif";
import BANNER from "../../assets/img/banner_inventory.png";
import FormImg from "../../assets/img/Formularios.png";
import PlannersForm from "../../components/Forms/PlannersForm";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../components/WhatsappIcon/WhatsappIcon";

const Inventory = () => {
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Content style={{ paddingTop: "103px" }}>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${Principal})`,
        }}
      >
        <Col lg={12} className="main-title-container">
          <Breadcrumb
            items={[
              {
                title: "Software",
              },
              {
                title: "Inventory Planning",
              },
            ]}
          />

          <div className="title-item">
            <h3 className="headline">Potencia tu Gestión de</h3>

            <h3
              className="headline"
              style={{
                color: "#9825F2",
              }}
            >
              Inventarios con Eficiencia y Precisión
            </h3>

            <p
              className="subtitle"
              style={{ marginTop: "35px", color: "#999" }}
            >
              Planeación de Inventarios Eficiente: Equilibra Demanda e Inversión
              con Precisión en Cada Punto de Venta o Centro de Distribución.
            </p>
          </div>
          <div className="button-item">
            <Button className="buttonPrimary" onClick={scrollToForm}>
              Lo quiero
            </Button>
            <Link to="/contacto">
              <Button className="button-secondary-b">
                Habla con un experto
              </Button>
            </Link>
          </div>
        </Col>
        <Col lg={12}>
          <div className="main-image-container">
            <Image
              src={INV3}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="modelo-predictivo-quiebre-inventario.gif"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div>
            <Link to="/contacto" className="contact-container">
              <Button className="buttonPrimary">Habla con un experto</Button>
            </Link>
            <WhatsappIcon />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} className="main-manage-title">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h3 className="headline">
              Los puntos claves de
              <span className="headline" style={{ color: "#9825F2" }}>
                {" "}
                un inventario eficiente
              </span>
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "45px",
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background-pri"
        style={{
          backgroundImage: `url(${P4})`,
        }}
      >
        <Col lg={11}>
          <div className="manage-text-container">
            <div>
              <h4 className="headline-sb">
                Toma decisiones
                <span style={{ color: "#9825F2" }}> basados en</span>
              </h4>
              <h4 className="headline-sb" style={{ color: "#9825F2" }}>
                la demanda
              </h4>
            </div>
            <p className="pre-title-r" >
              Ajusta el stock requerido en cada punto con base en la demanda
              esperada y la configuración logística de tu cadena. Así, compra de
              forma planificada y distribuye correctamente. Mientras más preciso
              sea el pronóstico, más eficiente será tu nivel de inventario.
            </p>
          </div>
        </Col>
        <Col lg={13}>
          <div className="manage-image-container">
            <Image
              src={INV}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="demanda-stock-inventario.gif"
            />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P2})`,
          height: "740px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="manage-image-container-two">
            <Image
              src={INV2}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="inventario-pronostico-sobrestock.gif"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="manage-text-container-two">
            <div className="container-two-title">
              <h4 className="headline-sb">
                Mejora el
                <span style={{ color: "#9825F2" }}> nivel de servicio </span>y
                ahorra en costos de inventario
              </h4>
            </div>
            <p className="pre-title-r">
              Con base en un pronóstico certero, tendrás en inventario la cantidad
              precisa para satisfacer tu demanda, aumentando el nivel de
              servicio a la vez que disminuye el sobrestock y el inventario
              inmovilizado.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={11}>
          <div className="manage-text-container-three">
            <div>
              <h4 className="headline-sb">
                Determina el
                <span style={{ color: "#9825F2" }}> impacto </span> de la falta
                de inventario
              </h4>
            </div>
            <p className="pre-title-r">
              Con un buen modelo predictivo, capaz de aislar periodos de alto
              quiebre, podrás medir el impacto de cuánto dejaste de vender por
              no tener inventario. Evalúa de forma cuantitativa la rentabilidad
              de modificar la estructura de despacho, la capacidad de bodegaje o
              distribución en base al beneficio de recuperar esa venta perdida.
            </p>
          </div>
        </Col>
        <Col lg={13}>
          <div className="manage-image-container-three">
            <Image
              src={INV3}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="modelo-predictivo-quiebre-inventario.gif"
            />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P3})`,
          height: "740px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="manage-image-container-four">
            <Image
              src={INV4}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="evaluacion-inventario.gif"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="manage-text-container-four">
            <div>
              <h4 className="headline-sb">
                Evalúa constantemente la
                <span style={{ color: "#9825F2" }}>
                  {" "}
                  salud de tu inventario
                </span>
              </h4>
            </div>
            <p className="pre-title-r">
              Monitorea constantemente indicadores como días de inventario,
              venta perdida, sobre-stock y stock inmovilizado; visibiliza
              alertas diarias y controla KPIs de alto nivel, de forma sencilla y
              amistosa, para centrar tu tiempo en la toma de decisiones.
            </p>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          background: "#FAF9FF",
        }}
      >
        <Col lg={14}>
          <div className="banner-pri">
            <h3 className="headline">
              Optimiza tu Éxito con una{" "}
              <span style={{ color: "#9825F2" }}>
                Gestión de Inventarios Potente y Precisa
              </span>
            </h3>
            <div className="banner-button">
              <Button className="buttonPrimary" onClick={scrollToForm}>
                Lo quiero
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={10}>
          <div className="banner-image">
            <Image src={BANNER} preview={false} />
          </div>
        </Col>
      </Row>
      <div ref={formRef} />
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${FormImg})`,
        }}
        justify={"center"}
      >
        <div className="form-container-pri">
          <PlannersForm title={"Inventory"} />
        </div>
      </Row>
    </Content>
  );
};

export default Inventory;
