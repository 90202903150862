import React from "react";
import { Card, Image, Avatar } from "antd";
import Star from "../../assets/img/Group2151.png";
import s from "./style.module.css";

const { Meta } = Card;

const TestimonialCard = ({ quote, author, position, image }) => {
  return (
    <div>
      <div className="testimonial-container">
        <Card>
          <div>
            <Image preview={false} src={Star} />
          </div>
          <div className="testimonial-quote">
            <p className="text-small">
              {quote}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "18px",
              paddingTop: "47px",
            }}
          >
            <Image preview={false} src={image} />
            <div>
              <h4 className="text-small" style={{ color: "#999" }}>
                {author}
              </h4>
              <h4
                className="text-small"
                style={{ color: "#999", fontWeight: "300" }}
              >
                {position}
              </h4>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TestimonialCard;
