import React from "react";
import { Navigate, Route, Routes, Link } from "react-router-dom";
import Home from "../pages/Home/Home";
import NavOptimal from "../components/common/Navbar/NavOptimal";
import FooterOptimal from "../components/common/Footer/FooterOptimal";
import KitPlannersCarga from "../pages/KitPlannersCarga/KitPlannersCarga";
import KitPlannersPasajeros from "../pages/KitPlannersPasajeros/KitPlannersPasajeros";
import Inventory from "../pages/Inventory/Inventory";
import Pricing from "../pages/Pricing/Pricing";
import Consultoria from "../pages/Consultoria/Consultoria";
import Nosotros from "../pages/Nosotros/Nosotros";
import Terminos from "../pages/Terminos/Terminos";
import Contacto from "../pages/Contacto/Contacto";
import About from "../pages/About/About";
import PrivacyPolicies from "../pages/PrivacyPolicies/PrivacyPolicies";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/contacto" element={<Contacto />} />
      <Route
        path="/*"
        element={
          <>
            <NavOptimal />
            <Routes>
              <Route index element={<Home />} />
              <Route
                path="/software/kit-planners-carga"
                element={<KitPlannersCarga />}
              />
              <Route
                path="/software/kit-planners-pasajeros"
                element={<KitPlannersPasajeros />}
              />
              <Route path="/software/inventory" element={<Inventory />} />
              <Route path="/software/pricing" element={<Pricing />} />
              <Route
                path="/asesorias/consultoria-logistica"
                element={<Consultoria />}
              />
              <Route
                path="/nosotros/trabaja-con-nosotros"
                element={<Nosotros />}
              />
              <Route path="/nosotros/quienes-somos" element={<About />} />
              <Route path="/terminos" element={<Terminos />} />
              <Route
                path="/politicas-de-privacidad"
                element={<PrivacyPolicies />}
              />
            </Routes>
            <FooterOptimal />
          </>
        }
      />
    </Routes>
  );
};

export default AppRouter;
