import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Image,
  Checkbox,
  message,
} from "antd";
import { useEffect } from "react";
import FlagCH from "../../assets/img/flag-chile.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;
const { Option } = Select;
const ContactForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const form = document.getElementById("idForm");
    form.addEventListener("submit", handleFormSubmit);

    return () => {
      form.removeEventListener("submit", handleFormSubmit);
    };
  }, []);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 100 }}>
        <Option value="56">
          <Image src={FlagCH} preview={false} /> +56
        </Option>
      </Select>
    </Form.Item>
  );

  const sendEmail = async (values) => {
    var template_data = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <title>Formulario </title>
      </head>
      <body>
        <h2>Datos de contacto:</h2>
        
        <p><strong>Email:</strong> ${values.email}</p>
        <p><strong>Teléfono:</strong> ${values.phone}</p>
        <p><strong>Nombre:</strong> ${values.name}</p>
        <p><strong>Compañía:</strong> ${values.company}</p>
        <h2> Datos de operación y ayuda:</h2>
        <p><strong>Detalles de la operación:</strong> ${values.operationDetails}</p>
        <h2> Soluciones de interés:</h2>`;

    if (values.pcarga) {
      template_data += `<p><strong>Carga:</strong> ${values.pcarga}</p>`;
    }

    if (values.ppasajero) {
      template_data += `<p><strong>Pasajero:</strong> ${values.ppasajero}</p>`;
    }

    if (values.clogistica) {
      template_data += `<p><strong>Logística:</strong> ${values.clogistica}</p>`;
    }

    if (values.inventory) {
      template_data += `<p><strong>Inventario:</strong> ${values.inventory}</p>`;
    }

    if (values.pricing) {
      template_data += `<p><strong>Precios:</strong> ${values.pricing}</p>`;
    }

    template_data += `
    </body>
  </html>
  `;
    const data = {
      from: "sender@theoptimalpartner.com",
      template: template_data,
      email_addresses: ["contacto@theoptimalpartner.com"],
      subject: "Formulario de contacto",
      attachments: [],
    };
    try {
      const response = await axios.post(
        "https://sonic.routingmobile.com/api/v1/llegando/deliver",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            ApiKey: "b5e4c14630bac2aeb66e7770",
          },
        }
      );
      console.log("Email sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault()

    try{
      sendEmail(form.getFieldsValue())
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gtm.formSubmit",
      });
  
      message.success(
        "¡Formulario enviado con éxito! Nos comunicaremos contigo en breve. Gracias por ponerte en contacto."
      );

      form.resetFields();

      navigate("/");

    } catch(error){
      message.error("Error al enviar el formulario. Por favor, inténtelo de nuevo.")
    }
  };



  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    message.info("Complete los campos obligatorios");
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #D9D9D9",
          background: "#FFF",
          padding: "20px",
          maxWidth: "711px",
        }}
      >
        <Form
          form={form}
          initialValues={{ prefix: "56" }}
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}
          id="idForm"
        >
          <Row className="row-form">
            <Col lg={11}>
              <Form.Item
                name="name"
                label="Nombre completo"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="nombre" autoComplete="off" />
              </Form.Item>
            </Col>
            <Col lg={11}>
              <Form.Item
                label="Correo electrónico"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "¡El correo es requerido!",
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="correo@correo.com" autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="row-form">
            <Col lg={11}>
              <Form.Item
                name="company"
                label="Empresa"
                rules={[
                  {
                    required: true,
                    message: "¡Empresa es requerido!",
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Empresa" autoComplete="off" />
              </Form.Item>
            </Col>
            <Col lg={11}>
              <Form.Item
                label="Teléfono de contacto:"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "¡El teléfono es requerido!",
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <Input
                  addonBefore={prefixSelector}
                  placeholder="XXXXXXXXX"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="row-form">
            <Col lg={24}>
              <Form.Item
                name="operationDetails"
                label={
                  <div className="operation-container">
                    <p>
                      Cuéntanos más sobre tu operación y en qué podemos ayudarte{" "}
                    </p>
                  </div>
                }
                labelCol={{ span: 24 }}
              >
                <TextArea rows={2} placeholder="Escribe aquí" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <div style={{ paddingLeft: "55px", paddingTop: "49px" }}>
                <h3 className="text-small">Soluciones de interés</h3>
                <div style={{ paddingTop: "18px" }}>
                  <Form.Item name="pcarga" valuePropName="checked">
                    <Checkbox>Planners carga</Checkbox>
                  </Form.Item>
                  <Form.Item name="ppasajero" valuePropName="checked">
                    <Checkbox>Planners pasajeros</Checkbox>
                  </Form.Item>
                  <Form.Item name="clogistica" valuePropName="checked">
                    <Checkbox>Consultoría logística</Checkbox>
                  </Form.Item>
                  <Form.Item name="inventory" valuePropName="checked">
                    <Checkbox>Inventory</Checkbox>
                  </Form.Item>{" "}
                  <Form.Item name="pricing" valuePropName="checked">
                    <Checkbox>Pricing</Checkbox>
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify={"end"} className="row-container-button">
            <Col>
              <Form.Item>
                <Button className="buttonPrimary btn-form" htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
