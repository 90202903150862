import { Menu, Button, Image } from "antd";
import { useEffect, useState } from "react";
import OptimalIcon from "../../../assets/img/Group36.png";
import { Header } from "antd/es/layout/layout";
import Icon from "../../../assets/img/icon.png";
import { Link, useLocation } from "react-router-dom";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const items = [
  {
    label: <Link to="/">Inicio</Link>,
    key: "home",
  },
  {
    label: (
      <span>
        Productos
        <Image src={Icon} preview={false} className="icon-arrow" />
      </span>
    ),
    key: "products",
    children: [
      {
        type: "group",
        label: "Software",
        children: [
          {
            label: (
              <Link to="/software/kit-planners-carga">Kit Planners Carga</Link>
            ),
            key: "/software/kit-planners-carga",
          },
          {
            label: (
              <Link to="/software/kit-planners-pasajeros">
                Kit Planners Pasajeros
              </Link>
            ),
            key: "/software/kit-planners-pasajeros",
          },
          {
            label: <Link to="/software/inventory">Inventory Planning</Link>,
            key: "/software/inventory",
          },
          {
            label: <Link to="/software/pricing">Pricing Modeling</Link>,
            key: "/software/pricing",
          },
        ],
      },
      {
        type: "group",
        label: "Asesorías",
        children: [
          {
            label: (
              <Link to="/asesorias/consultoria-logistica">
                Consultoría logística
              </Link>
            ),
            key: "/asesorias/consultoria-logistica",
          },
        ],
      },
    ],
  },
  {
    label: (
      <span>
        Nosotros
        <Image src={Icon} preview={false} className="icon-arrow" />
      </span>
    ),
    key: "us",
    children: [
      {
        label: (
          <Link to="/nosotros/quienes-somos">¿Quiénes somos?</Link>
        ),
        key: "/nosotros/quienes-somos",
      },
      {
        label: (
          <Link to="/nosotros/trabaja-con-nosotros">
           Trabaja con nosotros
          </Link>
        ),
        key: "/nosotros/trabaja-con-nosotros",
      },
    ],
  },
];

const findKeyForPath = (path, items) => {
  for (const item of items) {
    if (item.key && path.startsWith(item.key)) {
      return item.key;
    }
    if (item.children) {
      const childKey = findKeyForPath(path, item.children);
      if (childKey) {
        return childKey;
      }
    }
  }
  return null;
};

const NavOptimal = () => {
  const location = useLocation();
  const [currentKey, setCurrentKey] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedKey = findKeyForPath(currentPath, items);

    if (matchedKey) {
      setCurrentKey(matchedKey);
    } else {
      setCurrentKey("home");
    }

    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]);

  const handleClick = (e) => {
    setCurrentKey(e.key);
  };

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        background: "#FFF",
        height: "104px",
        borderBottom: "1px solid rgba(5,5,5, 0.06",
        position: "fixed",
        zIndex: 1,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: "162px",
          minHeight: "25.272px",
        }}
      >
        <Link to="/">
          <Image preview={false} src={OptimalIcon} />
        </Link>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
        <Menu
          mode="horizontal"
          items={items}
          selectedKeys={[currentKey]}
          onClick={handleClick}
          className="nav-menu"
          onOpenChange={(keys) => setMenuOpen(!!keys[0])}
          overflowedIndicator={
            menuOpen ? (
              <FontAwesomeIcon
                icon={faXmark}
                size="2xl"
                style={{ color: "#000000" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                style={{ color: "#000000" }}
                size="2xl"
              />
            )
          }
        />
      </div>
      <div className="btn-header">
        <Link to="/contacto">
          <div>
            <Button className="buttonPrimary">Habla con un experto</Button>
          </div>
        </Link>
      </div>
    </Header>
  );
};

export default NavOptimal;
