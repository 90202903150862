import { useState, useEffect, useRef } from "react";
import { Layout, Image, Row, Col, Button, Card, Carousel } from "antd";
import OptimalIcon from "../../assets/img/Group36.png";
import BackgroundForm from "../../assets/img/Formularios.png";
import ContactForm from "../../components/Forms/ContactForm";
import { Link, useLocation } from "react-router-dom";
import banner from "../../assets/img/Frame2277.png";
import LinkeIcon from "../../assets/img/Linkedin-icon.svg";
import InstagramIcon from "../../assets/img/instagram-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import TestimonialCard from "../../components/TestimonialCard/TestimonialCard";
import RecLat from "../../assets/img/rec_lat.png";
import RecEsach from "../../assets/img/rec_esach.png";
import RecTerpel from "../../assets/img/rec_terpel.png";
import RecDerco from "../../assets/img/rec_derco.png";
import RecInter from "../../assets/img/rec_intercarry.png";
import RecCop from "../../assets/img/rec_copeval.png";
import { useMediaQuery } from "react-responsive";

const { Header, Content, Footer } = Layout;
const Contacto = () => {
  const location = useLocation();
  const formRef = useRef(null);

  const [slidesToShow, setSlidesToShow] = useState(3);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isHd = useMediaQuery({ minWidth: 768, maxWidth: 1367 });

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (isMobile) {
      setSlidesToShow(1);
    } else if (isHd) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  }, [isMobile, isHd]);

  useEffect(() => {

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100); 

  });

  const testimonialData = [
    {
      quote:
        "“Son un socio estratégico en términos de mejora continua de procesos. Hemos logrado un ahorro del 18% versus el gato histórico”",
      author: "Gonzalo Díaz",
      role: "Subgerente Movilización Latam Airlines",
      image: RecLat,
    },
    {
      quote:
        "“Con Routing conectamos todos nuestros procesos operacionales y logramos mejorar un 20% la puntualidad”",
      author: "Alberto Murillo",
      role: "Gerente Operacionales Esachs",
      image: RecEsach,
    },
    {
      quote:
        '"Con el servicio de inventarios de ROUTING podemos mirar el inventario de una manera que nos permite prepararnos mejor para el futuro y tener más claridad del status actual. Con esto, hemos logrado aumentar la venta un 12% a la vez que bajamos la inversión necesaria en stock un 3%"',
      author: "José Germán Hernández Segura",
      role: "Consultor de negocios Terperl Colombia",
      image: RecTerpel,
    },
    {
      quote:
        "“¡Muy profesionales! Flexibles y abiertos a explorar nuevas alternativas que se ajusten a la necesidad de su cliente, siempre en la búsqueda de obtener el mejor resultado posible para alcanzar los objetivos propuestos, logrando una gran experiencia de trabajo”.",
      author: "Tomás Lorca Cammas",
      role: "Jefe Inteligencia de Negocios / Derco",
      image: RecDerco,
    },
    {
      quote:
        "“Implementamos un sistema de planificación, monitoreo y control en tiempo real que nos ha dado visibilidad y capacidad de gestionar a los transportistas y procesos internos, logrando así generar eficiencias operacionales y mejoras en nuestro nivel de servicio.”",
      author: "Javier Piña",
      role: "Gerente de transportes de Intercarry",
      image: RecInter,
    },
    {
      quote:
        "“Respecto a la plataforma operacional que utilizamos día a día, podemos destacar la capacidad de la cartografía que utilizamos para llegar a los campos de todos nuestros clientes, lo que luego se valida con los reportes de nuestros conductores. Esto también nos ha llevado a aumentar el nivel de servicio de cara a nuestros clientes, ya que podemos planificar y notificar la hora de llegada de los pedidos a destino. ”",
      author: "Sebastián Muñoz",
      role: "Gerente Cadena de Suministros Copeval",
      image: RecCop,
    },
  ];
  return (
    <Layout>
      <Header
        style={{
          textAlign: "start",
          backgroundColor: "#FFF",
          borderBottom: "1px solid rgba(5,5,5, 0.06)",
          height: "106px",
          position: "fixed",
          zIndex: "1",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Link to="/">
            <Image
              preview={false}
              src={OptimalIcon}
              width={"162px"}
              height={"25.392px"}
            />
          </Link>
        </div>
      </Header>
      <Content
        style={{
          backgroundColor: "#FFF",
          paddingTop: "103px",
        }}
      >
        <Row>
          <Col lg={24}>
            <div className="contact-title-container">
              <h3 className="headline-contact">
                Maximiza tu operación logística{" "}
                <span style={{ color: "#161227" }}>y</span> ganancias{" "}
                <span style={{ color: "#161227" }}>
                  con nuestra solución integrada de{" "}
                </span>
                precios e inventarios
              </h3>
            </div>
            <div style={{ textAlign: "center", marginTop: "57px" }}>
              <p className="pre-title" style={{ color: "#626161" }}>
                Escríbenos y te contactaremos a la brevedad
              </p>
            </div>
          </Col>
        </Row>
        <div ref={formRef}></div>
        <Row
          className="row-with-background"
          justify={"center"}
          style={{
            backgroundImage: `url(${BackgroundForm})`,

            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Col lg={24}>
            <div className="contact-form-container">
              <ContactForm />
            </div>
          </Col>
        </Row>
        <Row
          style={{
            background: "#FAF9FF",
          }}
        >
          <Col lg={16}>
            <div className="banner-contact">
              <h3 className="headline">
                Optimiza tu
                <span style={{ color: "#9825F2" }}> operación logística</span> y
                ganancias con nuestra{" "}
                <span style={{ color: "#9825F2" }}>
                  {" "}
                  solución integral de precios e inventarios
                </span>
              </h3>
              <div className="banner-button-contact">
                <Button className="button-secondary" onClick={scrollToForm}>
                  Habla con un asesor
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="banner-image-contact">
              <Image src={banner} preview={false} />
            </div>
          </Col>
        </Row>
        <Row style={{ background: "#FFF" }}>
          <Col xs={24} lg={24}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                paddingTop: "67px",
              }}
            >
              <FontAwesomeIcon
                icon={faQuoteRight}
                size="2xl"
                style={{ color: "#9825f2", height: "58px" }}
              />
              <h3 className="headline">¿Qué dicen nuestros clientes?</h3>
            </div>
          </Col>
          <Col xs={24} lg={24}>
            <div style={{ padding: "0 30px 0 30px" }}>
              <Carousel
                dotPosition="button"
                style={{ paddingBottom: "122px", paddingTop: "77px" }}
                slidesToShow={slidesToShow}
                infinite={false}
              >
                {testimonialData.map((testimonial, index) => (
                  <TestimonialCard
                    key={index}
                    quote={testimonial.quote}
                    author={testimonial.author}
                    position={testimonial.role}
                    image={testimonial.image}
                  />
                ))}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer style={{ background: "#FFF", paddingBottom: "43px" }}>
        <Row style={{ paddingTop: "84px" }}>
          <Col span={24}>
            <div style={{ height: "1px", background: "#D9D9D9" }} />
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={24}>
            <div className="footer-container-bottom">
              <p className="pre-title-r" style={{ color: "#999" }}>
                Copyright © 2023. Diseñado por team design de{" "}
                <span style={{ color: "#9825F2" }}>The Optimal.</span> Todos los
                derechos reservados.
              </p>
              <div className="footer-item-linkedin">
                <p className="pre-title-r" style={{ color: "#999" }}>
                  Encuéntranos en:
                </p>
                <Link
                  to={"https://www.linkedin.com/company/routing-spa/mycompany/"}
                >
                  <Image preview={false} src={LinkeIcon} alt="Linkedin-icon.svg" />
                </Link>
                <Link
                to={"https://www.instagram.com/theoptimalpartner/"}
              >
                <Image preview={false} src={InstagramIcon} alt="instagram-icon.svg"/>
              </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default Contacto;
