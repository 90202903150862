import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const ItemCollapse = ({ description }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", paddingLeft: "25px", gap: "13px"}}>
      <FontAwesomeIcon
        icon={faCircleCheck}
        size="2xl"
        style={{ color: "#9825f2" }}
      />
      <p className="text-small">{description}</p>
    </div>
  );
};

export default ItemCollapse;
