import React from "react";
import { Col, Layout, Row, Image, Typography } from "antd";
import { Link } from "react-router-dom";
import OptimalIcon from "../../../assets/img/Group36.png";
import ChileIcon from "../../../assets/img/twemoji_flag-chile.png";
import PeruIcon from "../../../assets/img/twemoji_flag-peru.png";
import ColombiaIcon from "../../../assets/img/twemoji_flag-colombia.png";
import BrazilIcon from "../../../assets/img/twemoji_flag-brazil.png";
import LinkeIcon from "../../../assets/img/Linkedin-icon.svg";
import InstagramIcon from "../../../assets/img/instagram-icon.svg";
import LogoFooter from "../../../assets/img/logo_footer.svg";

const { Footer } = Layout;
const { Text } = Typography;

const FooterOptimal = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Footer style={{ background: "#FFFFFF" }}>
      <Row>
        <Col xs={24} lg={8}>
          <div className="footer-container-left">
            <Image src={LogoFooter} preview={false} />
            <div
              style={{
                paddingTop: "75px",
                display: "flex",
                flexDirection: "column",
                gap: "7px",
              }}
            >
              <p className="pre-title-r">
                Francisco Noguera 200, Of. 1301, Providencia, Santiago de Chile
              </p>
              <p className="pre-title-r" style={{ color: "#9825F2" }}>
                contacto@theoptimalpartner.com
              </p>
            </div>
            <div style={{ paddingTop: "37px" }}>
              <p className="pre-title-r">(+56 2) 29521536</p>
              <div style={{ display: "flex", gap: "27px", paddingTop: "54px" }}>
                <Image src={ChileIcon} preview={false} />
                <Image src={PeruIcon} preview={false} />
                <Image src={ColombiaIcon} preview={false} />
                <Image src={BrazilIcon} preview={false} />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="footer-container-product">
            <h2 className="subtitle-medium">Productos</h2>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              <Link to="/software/kit-planners-carga">
                <p
                  className="pre-title-r link-footer"
                  style={{ color: "#999" }}
                >
                  Planners Carga
                </p>
              </Link>
              <Link to="/software/kit-planners-pasajeros">
                <p
                  className="pre-title-r link-footer"
                  style={{ color: "#999" }}
                >
                  Planners Pasajeros
                </p>
              </Link>
              <Link to="/software/inventory">
                <p
                  className="pre-title-r link-footer"
                  style={{ color: "#999" }}
                >
                  Inventory Planning
                </p>
              </Link>
              <Link to="/software/pricing">
                <p
                  className="pre-title-r link-footer"
                  style={{ color: "#999" }}
                >
                  Pricing Modeling
                </p>
              </Link>
              <Link to="/asesorias/consultoria-logistica">
                <p
                  className="pre-title-r link-footer"
                  style={{ color: "#999" }}
                >
                  Consultoría Logística
                </p>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={5}>
          <div className="footer-container-product item-about">
            <h2 className="subtitle-medium">Nosotros</h2>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              <Link to="/nosotros/quienes-somos">
                <p
                  className="pre-title-r link-footer"
                  style={{ color: "#999" }}
                >
                  ¿Quiénes somos?
                </p>
              </Link>
              <Link to="/nosotros/trabaja-con-nosotros">
                <p
                  className="pre-title-r link-footer"
                  style={{ color: "#999" }}
                >
                  Trabaja con nosotros
                </p>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={5}>
          <div className="footer-container-company">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "18px" }}
            >
              <h2 className="subtitle-medium">Compañía</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Link to="/contacto">
                  <p
                    className="pre-title-r link-footer"
                    style={{ color: "#999" }}
                  >
                    Contáctanos
                  </p>
                </Link>
                <Link to="/terminos">
                  <p
                    className="pre-title-r link-footer"
                    style={{ color: "#999" }}
                  >
                    Términos y condiciones
                  </p>
                </Link>
                <Link to="/terminos">
                  <p
                    className="pre-title-r link-footer"
                    style={{ color: "#999" }}
                  >
                    Políticas de privacidad
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{
          height: "1px",
          background: "#D9D9D9",
          paddingLeft: "61px",
          paddingRight: "61px",
        }}
      />
      <Row>
        <Col xs={24} lg={24}>
          <div className="footer-container-bottom">
            <p className="pre-title-r" style={{ color: "#999" }}>
              Copyright © {currentYear}. Diseñado por team design de{" "}
              <span style={{ color: "#9825F2" }}>The Optimal.</span> Todos los
              derechos reservados.
            </p>
            <div className="footer-item-linkedin">
              <p className="pre-title-r" style={{ color: "#999" }}>
                Encuéntranos en:
              </p>
              <div style={{ display: "flex", gap: '20px', marginTop: '10px' }}>
                <Link
                  to={"https://www.linkedin.com/company/routing-spa/mycompany/"}
                >
                  <Image
                    preview={false}
                    src={LinkeIcon}
                    alt="Linkedin-icon.svg"
                  />
                </Link>
                <Link to={"https://www.instagram.com/theoptimalpartner/"}>
                  <Image
                    preview={false}
                    src={InstagramIcon}
                    alt="instagram-icon.svg"
                  />
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Footer>
  );
};

export default FooterOptimal;
