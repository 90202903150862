import React from "react";
import { Image } from "antd";
import IconWhatsapp from "../../assets/img/icono_WhatsApp.png";

const WhatsappIcon = () => {
  return (
    <a href="https://wa.me/56992262139" target="_blank" style={{listStyle: "none"}} className="whatsapp-container">
      <Image src={IconWhatsapp} preview={false} />
    </a>

  );
};

export default WhatsappIcon;
