import React, { useRef } from "react";
import { Col, Row, Breadcrumb, Button, Image } from "antd";
import { Content } from "antd/es/layout/layout";
import Principal from "../../assets/img/Principal.png";
import P4 from "../../assets/img/Home_4.png";
import P2 from "../../assets/img/Productos_2.png";
import P3 from "../../assets/img/Productos_3.png";
import PRI from "../../assets/img/segmentacion-productos-clientes-pricing.gif";
import PRI1 from "../../assets/img/estrategia-de-negocio-precios-pricing.gif";
import PRI2 from "../../assets/img/segmentacion-productos-clientes-pricing-2.gif";
import PRI3 from "../../assets/img/pricing-aumento-rentabilidad-toma-de-decisiones.gif";
import PRI4 from "../../assets/img/medicion-impacto-decisiones-pricing.gif";
import BANNER from "../../assets/img/price.png";
import FormImg from "../../assets/img/Formularios.png";
import PlannersForm from "../../components/Forms/PlannersForm";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../components/WhatsappIcon/WhatsappIcon";

const Pricing = () => {
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Content style={{ paddingTop: "103px" }}>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${Principal})`,
          height: "792px",
        }}
      >
        <Col lg={12} className="main-title-container">
          <Breadcrumb
            items={[
              {
                title: "Software",
              },
              {
                title: "Pricing Modeling",
              },
            ]}
          />

          <div className="title-item">
            <h3 className="headline">Optimización de Precios:</h3>
            <h3
              className="headline"
              style={{
                color: "#9825F2",
              }}
            >
              Potencia tu Estrategia <span className="headline">de</span>
            </h3>
            <h3 className="headline">Gestión</h3>

            <p
              className="subtitle"
              style={{ marginTop: "35px", color: "#999" }}
            >
              Potente Gestión de Precios: Define, evalúa y maximiza tus
              estrategias de negocio de forma eficiente. ¡Optimiza tus
              decisiones y beneficios con nuestra plataforma.
            </p>
          </div>
          <div className="button-item">
            <Button className="buttonPrimary" onClick={scrollToForm}>
              Lo quiero
            </Button>
            <Link to="/contacto" className="item-button-secondary">
              <Button className="button-secondary-b">
                Habla con un experto
              </Button>
            </Link>
          </div>
        </Col>
        <Col lg={12}>
          <div className="main-image-container">
            <Image
              src={PRI}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="segmentacion-productos-clientes-pricing.gif"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div>
            <Link to="/contacto" className="contact-container">
              <Button className="buttonPrimary">Habla con un experto</Button>
            </Link>
            <WhatsappIcon />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} className="main-manage-title">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <h3 className="headline">
              Gestiona tus precios en 4 simples pasos
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "45px",
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background-pri"
        style={{
          backgroundImage: `url(${P4})`,
        }}
      >
        <Col lg={11}>
          <div className="manage-text-container">
            <div>
              <h4 className="headline-sb">
                Define tu
                <span style={{ color: "#9825F2" }}> estrategia de negocio</span>
              </h4>
            </div>
            <p className="pre-title-r">
              Configura con cuántas listas de precios trabajarás y qué reglas
              deben cumplir para lograr los objetivos deseados: aumentar
              rentabilidad, crecer en participación de mercado, ser distinguido
              como económico, fidelización de clientes; entre otras opciones.
            </p>
          </div>
        </Col>
        <Col lg={13}>
          <div className="manage-image-container">
            <Image
              src={PRI1}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="estrategia-de-negocio-precios-pricing.gif"
            />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P2})`,
          height: "740px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="manage-image-container-two">
            <Image
              src={PRI2}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="segmentacion-productos-clientes-pricing-2.gif"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="manage-text-container-two">
            <div className="container-two-title">
              <h4 className="headline-sb">
                Diferencia tu estrategia para cada
                <span style={{ color: "#9825F2" }}> tipo de producto</span>
              </h4>
            </div>
            <p className="pre-title-r">
              Combina tu segmentación propia de productos o clientes con la
              clasificación que ofrece la plataforma, permitiendo distinguir
              aquellos segmentos con mayor elasticidad precio-demanda y así
              sacar el máximo provecho a tu estrategia.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={11}>
          <div className="manage-text-container-three">
            <div>
              <h4 className="headline-sb">
                Toma decisiones con
                <span style={{ color: "#9825F2" }}> mayor información </span>
              </h4>
            </div>
            <p className="pre-title-r">
              Podrás evaluar entre varios escenarios simulados de precio,
              considerando el impacto en los indicadores más relevantes como
              contribución, ingreso y unidades vendidas, permitiendo elegir el
              mejor equilibrio para aumentar rentabilidad.
            </p>
          </div>
        </Col>
        <Col lg={13}>
          <div className="manage-image-container-three">
            <Image
              src={PRI3}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="pricing-aumento-rentabilidad-toma-de-decisiones.gif"
            />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P3})`,
          height: "740px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="manage-image-container-four">
            <Image
              src={PRI4}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="medicion-impacto-decisiones-pricing.gif"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="manage-text-container-four">
            <div>
              <h4 className="headline-sb">
                Mide el
                <span style={{ color: "#9825F2" }}> impacto </span>
                de tus decisiones
              </h4>
            </div>
            <p className="pre-title-r">
              Evalúa de forma gráfica y simple si el resultado obtenido es el
              esperado. Detecta y corrige aquellos casos que lo necesiten y
              obtén mayor información para la decisión del siguiente periodo.
            </p>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          background: "#FAF9FF",
        }}
      >
        <Col lg={14}>
          <div className="banner-pri">
            <h3 className="headline" style={{ color: "#9825F2" }}>
              Maximiza tus ganancias
              <span className="headline">
                {" "}
                con nuestra poderosa optimización de precios
              </span>
            </h3>
            <div className="banner-button">
              <Button className="buttonPrimary" onClick={scrollToForm}>
                Lo quiero
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={10}>
          <div className="banner-image">
            <Image src={BANNER} preview={false} />
          </div>
        </Col>
      </Row>
      <div ref={formRef}></div>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${FormImg})`,
        }}
        justify={"center"}
      >
        <div className="form-container-pri">
          <PlannersForm title={"Pricing"} />
        </div>
      </Row>
    </Content>
  );
};

export default Pricing;
