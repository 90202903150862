import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Image,
  Card,
  Breadcrumb,
  Steps,
  Popover,
} from "antd";

import { useMediaQuery } from "react-responsive";
import ReactPlayer from "react-player";
import Video from "../../assets/video/the-optimal-nosotros.mp4";
import { Icon } from "@iconify/react";
import Optimal from "../../assets/img/about/optimal.svg";
import Pricing from "../../assets/img/about/pricing.svg";
import Routing from "../../assets/img/about/routing.svg";
import RoutingUC from "../../assets/img/about/routinguc.svg";
import D1 from "../../assets/img/about/director1.svg";
import D2 from "../../assets/img/about/director2.svg";
import CEO from "../../assets/img/about/ceo.svg";
import Linkedin from "../../assets/img/about/linkedin.svg";
import { Link } from "react-router-dom";

const { Content } = Layout;

const leaderTitle = {
  lineHeight: "28px",
  paddingTop: "14px",
  textAlign: "center",
  fontSize: "20px",
  fontWeight: "500",
};

const leaderDescription = {
  lineHeight: "25.2px",
  textAlign: "justify",
  fontSize: "18px",
  color: "#000000",
};

const leaderDescriptionContainer = {
  height: "152px",
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
};

const About = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [showVideo, setShowVideo] = useState(true);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isHd = useMediaQuery({ minWidth: 768, maxWidth: 1367 });

  useEffect(() => {
    if (isMobile) {
      console.log(isMobile, isHd);
      setSlidesToShow(1);
      setShowVideo(false);
    } else if (isHd) {
      setSlidesToShow(2);
      setShowVideo(true);
    } else {
      setSlidesToShow(3);
    }
  }, [isMobile, isHd]);

  return (
    <Content style={{ paddingTop: "103px", marginBottom: "200px" }}>
      <Row
        className="row-with-background"
        style={{
          backgroundSize: "contain",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          textAlign: "center",
        }}
      >
        <Col span={20} offset={2}>
          <Breadcrumb
            style={{ marginTop: "46px" }}
            items={[
              {
                title: "Nosotros",
              },
              {
                title: "¿Quiénes somos?",
              },
            ]}
          />
        </Col>
        <Col lg={24}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="n-main-title">
              <h3
                className="headline"
                style={{
                  color: "#9825F2",
                }}
              >
                ¿Quiénes somos?
              </h3>
            </div>
            <div className="n-text-container">
              <p
                className="pre-title-r"
                style={{ textAlign: "center", lineHeight: "35px" }}
              >
                The Optimal, fundada en 2023 tras la fusión de Dictuc Pricing
                SpA y Routing UC (Spin-OFFs de 2015 y 2018 respectivamente), es
                líder en soluciones logísticas end-to-end. Ofrece servicios
                integrales en la cadena de suministros, desde diseño de red
                logística hasta gestión de inventarios y fijación de precios,
                optimizando procesos para mayor eficiencia.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            {showVideo ? (
              <div className="n-video-d">
                <ReactPlayer
                  playing
                  url={Video}
                  width="100%"
                  alt="the-optimal-nosotros.mp4"
                />
              </div>
            ) : (
              <div className="n-video-m">
                <ReactPlayer
                  playing
                  url={Video}
                  width="100%"
                  alt="the-optimal-nosotros.mp4"
                  controls
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row justify={"center"} style={{ marginTop: "100px", gap: "60px" }}>
        <Col lg={{ span: 8 }} md={{ span: 10 }}  xs={{ span: 20 }}>
          <Card>
            <div className="icon_container">
              <Icon icon="noto:bullseye" width={"48px"} />
            </div>
            <p
              className="headline-sb"
              style={{
                lineHeight: "150%",
                paddingTop: "14px",
                textAlign: "center",
              }}
            >
              MISIÓN
            </p>
            <div
              style={{ height: "152px", display: "flex", alignItems: "center" }}
            >
              <p
                className="pre-title-r"
                style={{
                  lineHeight: "150%",
                  textAlign: "center",
                }}
              >
                Entregar tecnología y soluciones a problemáticas relacionadas
                con la cadena de suministro.
              </p>
            </div>
          </Card>
        </Col>
        <Col lg={{ span: 8 }} md={{ span: 10 }}  xs={{ span: 20 }}>
          <Card>
            <div className="icon_container">
              <Icon icon="noto:eye" width={"48px"} />
            </div>
            <p
              className="headline-sb"
              style={{
                lineHeight: "150%",
                paddingTop: "14px",
                textAlign: "center",
              }}
            >
              VISIÓN
            </p>
            <div
              style={{ height: "152px", display: "flex", alignItems: "center" }}
            >
              <p
                className="pre-title-r"
                style={{
                  lineHeight: "150%",
                  textAlign: "center",
                }}
              >
                Ser referente en el desarrollo de soluciones integrales para
                procesos logísticos a nivel nacional y latinoamericano.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row justify={"center"}>
        <Col>
          <div className="about-container">
            <h3 className="headline">
              Nuestra
              <span style={{ color: "#9825F2" }}> Historia</span>
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="time-line-container">
        <Col lg={{ span: 18 }} xxl={{ span: 17 }} sm={{span: 24}}>
          <Steps
            progressDot={(dot, step) => (
              <>
                {step.index === 0 && (
                  <div className="time-line">
                    <Image src={Pricing} preview={false} width={109} />
                  </div>
                )}
                {step.index === 1 && (
                  <div className="time-line">
                    <Image src={RoutingUC} preview={false} width={109} />
                  </div>
                )}
                {step.index === 2 && (
                  <div className="time-line">
                    <Image src={Pricing} preview={false} width={109} />
                  </div>
                )}
                {step.index === 3 && (
                  <div className="time-line">
                    <Image src={Routing} preview={false} width={109} />
                  </div>
                )}
                {step.index === 4 && (
                  <div className="time-line">
                    <Image src={Optimal} preview={false} width={109} />
                  </div>
                )}
                <Popover>{dot}</Popover>
              </>
            )}
            items={[
              {
                title: (
                  <span
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                      color: "#000000D9",
                    }}
                  >
                    2006
                  </span>
                ),
                status: "finish",
                description: (
                  <div style={{ width: "200px" }}>
                    <span
                      style={{
                        display: "flex",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000D9",
                        width: "max-content",
                      }}
                    >
                      Nace Pricing UC
                    </span>
                    <span
                      style={{
                        display: "flex",
                        textAlign: "start",
                        marginTop: "50px",
                      }}
                    >
                      Inicio bajo la Pontificia Universidad Católica de Chile y
                      DICTUC S.A., especializándose en Pricing & Revenue
                      Management.
                    </span>
                  </div>
                ),
              },
              {
                title: (
                  <span
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                      color: "#000000D9",
                    }}
                  >
                    2012
                  </span>
                ),
                status: "finish",
                description: (
                  <div style={{ width: "200px" }}>
                    <span
                      style={{
                        display: "flex",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000D9",
                        width: "max-content",
                      }}
                    >
                      Nace Routing UC
                    </span>
                    <span
                      style={{
                        display: "flex",
                        textAlign: "start",
                        marginTop: "50px",
                      }}
                    >
                      Comienza como parte de la Pontificia Universidad Católica
                      de Chile y DICTUC S.A., enfocado en transporte, logística
                      y distribución.
                    </span>
                  </div>
                ),
              },
              {
                title: (
                  <span
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                      color: "#000000D9",
                    }}
                  >
                    2015
                  </span>
                ),
                status: "finish",
                description: (
                  <div>
                    <span
                      style={{
                        display: "flex",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000D9",
                        width: "max-content",
                      }}
                    >
                      Spin off de Dictuc:
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000D9",
                      }}
                    >
                      Pricing SpA
                    </span>
                    <span
                      style={{
                        display: "flex",
                        textAlign: "start",
                        marginTop: "20px",
                        width: "200px",
                      }}
                    >
                      Pricing UC se convierte en Pricing SpA, destacándose en
                      sectores como retail, agroindustria y automotriz,
                      empleando modelos cuantitativos para optimizar objetivos
                      empresariales clave.
                    </span>
                  </div>
                ),
              },
              {
                title: (
                  <span
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                      color: "#000000D9",
                    }}
                  >
                    2018
                  </span>
                ),
                status: "finish",
                description: (
                  <div>
                    <span
                      style={{
                        display: "flex",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000D9",
                        width: "max-content",
                      }}
                    >
                      Spin off de Dictuc:
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000D9",
                      }}
                    >
                      Routing SpA
                    </span>
                    <span
                      style={{
                        display: "flex",
                        textAlign: "start",
                        marginTop: "20px",
                        width: "200px",
                      }}
                    >
                      Routing UC se convierte en Dictuc Routing SpA, ofreciendo
                      soluciones de transporte, logística y distribución,
                      incluyendo última milla y transporte de pasajeros.
                    </span>
                  </div>
                ),
              },
              {
                title: (
                  <span
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                      color: "#000000D9",
                    }}
                  >
                    2023
                  </span>
                ),
                status: "finish",
                description: (
                  <div>
                    <span
                      style={{
                        display: "flex",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#000000D9",
                        width: "max-content",
                      }}
                    >
                      Nace The Optimal Partner
                    </span>
                    <span
                      style={{
                        display: "flex",
                        textAlign: "start",
                        marginTop: "45px",
                        width: "250px",
                      }}
                    >
                      Fusión de Routing y Pricing, surge The Optimal Partner,
                      líder en soluciones logísticas end-to-end, abarcando
                      consultoría logística, optimización de rutas, predicción
                      de demanda y gestión de inventarios para una cadena de
                      suministro más eficiente.
                    </span>
                  </div>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Row
        style={{
          background: "#FAF9FF",
          textAlign: "center",
          marginTop: "180px",
        }}
        justify={'center'}
      >
        <Col lg={24}>
          <div className="about-container">
            <h3 className="headline">
              Nuestros
              <span style={{ color: "#9825F2" }}> Líderes</span>
            </h3>
            <div></div>
          </div>
        </Col>
        <Row justify={"center"} style={{ marginTop: "100px", gap: "60px" }}>
          <Col
            xxl={{ span: 7 }}
            xl={{ span: 7 }}
            lg={{ span: 11 }}
            md={{ span: 10 }}
            xs={{ span: 20 }}
          >
            <Card>
              <div>
                <Image src={D2} width={207} preview={false} />
              </div>
              <p style={leaderTitle}>Juan Carlos Ferrer</p>
              <div style={leaderDescriptionContainer}>
                <p style={leaderDescription}>
                  Profesor Titular de Ingeniería en Pontificia Universidad
                  Católica de Chile. Ph. D Management MIT. Áreas Gestión de
                  Operaciones y Optimización
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                <Link to={"https://www.linkedin.com/in/jc-ferrer/"}>
                  <Image
                    preview={false}
                    src={Linkedin}
                    alt="Linkedin-icon.svg"
                  />
                </Link>
              </div>
            </Card>
          </Col>
          <Col
            xxl={{ span: 7 }}
            xl={{ span: 7 }}
            lg={{ span: 11 }}
            md={{ span: 10 }}
            xs={{ span: 20 }}
          >
            <Card>
              <div>
                <Image src={D1} width={207} preview={false} />
              </div>
              <p style={leaderTitle}>Ricardo Giesen</p>
              <div style={leaderDescriptionContainer}>
                <p style={leaderDescription}>
                  Director del Depto. de Ingeniería de Transporte y Logística en
                  Pontificia Universidad Católica de Chile.Ph. D. Maryland.
                  Áreas Logística y Transportes
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                <Link
                  to={"https://www.linkedin.com/in/ricardo-giesen-b1624919/"}
                >
                  <Image
                    preview={false}
                    src={Linkedin}
                    alt="Linkedin-icon.svg"
                  />
                </Link>
              </div>
            </Card>
          </Col>
          <Col
            xxl={{ span: 7 }}
            xl={{ span: 7 }}
            lg={{ span: 11 }}
            md={{ span: 10 }}
            xs={{ span: 20 }}
          >
            <Card>
              <div>
                <Image src={CEO} width={207} preview={false} />
              </div>
              <p style={leaderTitle}>Francisco Hornauer</p>
              <div style={leaderDescriptionContainer}>
                <p style={leaderDescription}>
                  Ingeniero Civil Industrial PUC, con experiencia en gestión de
                  empresas y exitosa trayectoria en compañías nacionales y
                  multinacionales
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                <Link to={"https://www.linkedin.com/in/franciscohornauer/"}>
                  <Image
                    preview={false}
                    src={Linkedin}
                    alt="Linkedin-icon.svg"
                  />
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Row>
      <Row>
        <Col xs={24} lg={24}>
          <div className="team-container">
            <h3 className="headline">
              ¿Por qué<span style={{ color: "#9825F2" }}> The optimal</span>?
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "64px",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row justify={"center"} style={{ marginTop: "100px", gap: "60px" }}>
        <Col
          xxl={{ span: 7 }}
          xl={{ span: 7 }}
          lg={{ span: 7 }}
          md={{ span: 10 }}
          xs={{ span: 20 }}
        >
          <Card>
            <div className="icon_container">
              <Icon
                icon="noto:handshake-light-skin-tone-medium-light-skin-tone"
                width={"45px"}
              />
            </div>
            <div
              style={{ height: "152px", display: "flex", alignItems: "center" }}
            >
              <p
                className="pre-title-r"
                style={{
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Mantenemos una conexión constante con la academia y las últimas
                tendencias, aprovechando nuestra afiliación con la UC.
              </p>
            </div>
          </Card>
        </Col>
        <Col
          xxl={{ span: 7 }}
          xl={{ span: 7 }}
          lg={{ span: 7 }}
          md={{ span: 10 }}
          xs={{ span: 20 }}
        >
          <Card>
            <div className="icon_container">
              <Icon icon="noto:military-medal" width={"45px"} />
            </div>
            <div
              style={{ height: "152px", display: "flex", alignItems: "center" }}
            >
              <p
                className="pre-title-r"
                style={{
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Proporcionamos un servicio altamente dedicado para maximizar el
                valor de nuestros softwares y servicios para nuestros clientes.
              </p>
            </div>
          </Card>
        </Col>
        <Col
          xxl={{ span: 7 }}
          xl={{ span: 7 }}
          lg={{ span: 7 }}
          md={{ span: 10 }}
          xs={{ span: 20 }}
        >
          <Card>
            <div className="icon_container">
              <Icon icon="noto:antenna-bars" width={"45px"} />
            </div>
            <div
              style={{ height: "152px", display: "flex", alignItems: "center" }}
            >
              <p
                className="pre-title-r"
                style={{
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Nos centramos en mejorar continuamente nuestros procesos,
                utilizando la automatización para reducir costos.
              </p>
            </div>
          </Card>
        </Col>
        <Col
          xxl={{ span: 7 }}
          xl={{ span: 7 }}
          lg={{ span: 7 }}
          md={{ span: 10 }}
          xs={{ span: 20 }}
        >
          <Card>
            <div className="icon_container">
              <Icon icon="noto:light-bulb" width={"45px"} />
            </div>
            <div
              style={{ height: "152px", display: "flex", alignItems: "center" }}
            >
              <p
                className="pre-title-r"
                style={{
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Desarrollamos soluciones flexibles y escalables, adaptadas a las
                necesidades específicas de nuestros clientes.
              </p>
            </div>
          </Card>
        </Col>
        <Col
          xxl={{ span: 7 }}
          xl={{ span: 7 }}
          lg={{ span: 7 }}
          md={{ span: 10 }}
          xs={{ span: 20 }}
        >
          <Card>
            <div className="icon_container">
              <Icon icon="noto:gear" width={"45px"} />
            </div>
            <div
              style={{ height: "152px", display: "flex", alignItems: "center" }}
            >
              <p
                className="pre-title-r"
                style={{
                  lineHeight: "150%",
                  textAlign: "justify",
                }}
              >
                Ofrecemos consultorías logísticas complejas, optimizadas con
                nuestros softwares, reduciendo costos y garantizando soluciones
                eficientes y de alta calidad.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default About;
