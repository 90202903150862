import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const FeatureItem = ({ description }) => {
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <FontAwesomeIcon
        icon={faCircleCheck}
        size="2xl"
        style={{ color: "#9825f2", paddingRight: "13px" }}
      />
      <p className="pre-title" style={{ color: "#000" }}>
        {description}
      </p>
    </div>
  );
};

export default FeatureItem;
