import { useRef } from "react";
import { Content } from "antd/es/layout/layout";
import { Col, Row, Breadcrumb, Button, Collapse, Image } from "antd";
import PlannersForm from "../../components/Forms/PlannersForm";
import PCarga from "../../assets/img/monitoreo-tiempo-real-control-de-flota.gif";
import Principal from "../../assets/img/Principal.png";
import P2 from "../../assets/img/Productos_2.png";
import P3 from "../../assets/img/Productos_3.png";
import P4 from "../../assets/img/Home_4.png";
import Pr4 from "../../assets/img/Productos_4.png";
import FormImg from "../../assets/img/Formularios.png";
import PlannerC1 from "../../assets/img/planificacion-rutas-optimizadas-TMS-saas.gif";
import PlannerC2 from "../../assets/img/app-conductores-proof-of-delivery.gif";
import PlannerC3 from "../../assets/img/monitoreo-tiempo-real- control-de-flota-saas.gif";
import PlannerC4 from "../../assets/img/notificacion-clientes.gif";
import PlannerC5 from "../../assets/img/pcarga5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ItemCollapse from "../../components/ItemCollapse/ItemCollapse";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../components/WhatsappIcon/WhatsappIcon";
import FeatureItem from "../../components/FeatureItem/FeatureItem";

const KitPlannersCarga = () => {
  const formRef = useRef(null);
  const items = [
    {
      key: "1",
      label: "Central monitoreo",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "39px" }}>
          <ItemCollapse
            description={"Revisar ubicación y estados de las rutas y entregas"}
          />
          <ItemCollapse description={"Tablero KPI en vivo"} />
          <ItemCollapse
            description={"Búsqueda de entregas, rutas, clientes y vehículos"}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Monitoreo y gestión de las rutas en vivo",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "39px" }}>
          <ItemCollapse
            description={
              "Cambiar orden de entregas durante la ejecución de las rutas."
            }
          />
          <ItemCollapse
            description={"Opción de modificación de entregas en tiempo real."}
          />
          <ItemCollapse description={"Actualización automática del ETA"} />
          <ItemCollapse
            description={
              "Actualización en los dispositivos de los conductores."
            }
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Reporteria",
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "39px" }}>
          <ItemCollapse description={"Reportería estándar en Excel"} />
          <ItemCollapse
            description={
              "Reportería 100% a medida con herramientas BI (Tableau)"
            }
          />
        </div>
      ),
    },
  ];

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Content style={{ paddingTop: "103px" }}>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${Principal})`,
          height: "725px",
        }}
      >
        <Col lg={12} className="main-title-container">
          <Breadcrumb
            items={[
              {
                title: "Software",
              },
              {
                title: "Kit Planners Carga",
              },
            ]}
          />

          <div className="title-item">
            <h3 className="headline">Planifica tus rutas y</h3>
            <h3 className="headline">
              Optimiza a tiempo{" "}
              <span
                style={{
                  color: "#9825F2",
                }}
              >
                tus envíos
              </span>
            </h3>

            <p
              className="subtitle"
              style={{ marginTop: "35px", color: "#999" }}
            >
              Optimiza tu logística de carga con la mejor plataforma SaaS TMS de
              planificación de rutas. Trazabilidad y monitoreo de tus pedidos en
              todo momento.
            </p>
          </div>
          <div className="button-item">
            <Button className="buttonPrimary" onClick={scrollToForm}>
              Lo quiero
            </Button>
            <Link to="/contacto">
              <Button className="button-secondary-b">
                Habla con un experto
              </Button>
            </Link>
          </div>
        </Col>
        <Col lg={12} className="main-image-container">
          <Image
            src={PCarga}
            preview={false}
            style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
            alt="monitoreo-tiempo-real-control-de-flota.gif"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div>
            <Link to="/contacto" className="contact-container">
              <Button className="buttonPrimary">Habla con un experto</Button>
            </Link>
            <WhatsappIcon />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} className="main-manage-title">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <p className="headline">
              ¡
              <span className="headline" style={{ color: "#9825F2" }}>
                Potencia tus despachos
              </span>{" "}
              con nuestro kit
            </p>
            <p className="headline" style={{ marginBottom: "32px" }}>
              de Gestión!
            </p>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          marginTop: "89px",
          backgroundImage: `url(${P4})`,
          backgroundPosition: "revert",
        }}
      >
        <Col lg={12}>
          <div className="pcarga-text-container">
            <h4 className="headline-sb" style={{ fontWeight: "600" }}>
              Planifica <span style={{ color: "#9825F2" }}>tus envíos</span>
            </h4>
            <p className="pre-title" style={{ lineHeight: "150%" }}>
              Optimiza tu logística sin esfuerzo: Planificación de rutas 100%
              automatizada y libre de papel. Integra cada detalle para tus
              visitas: Ubicaciones, tiempos de parada, ventanas horarias,
              vehículos, carga y conductores, ¡Todo en uno!
            </p>
          </div>
        </Col>
        <Col lg={12}>
          <div className="pcarga-image-container">
            <Image
              src={PlannerC1}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="planificacion-rutas-optimizadas-TMS-saas.gif"
            />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P2})`,
          marginTop: "85px",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="pcarga-image-container-two">
            <Image src={PlannerC2} preview={false} alt="app-conductores-proof-of-delivery.gif" />
          </div>
        </Col>
        <Col lg={12}>
          <div className="pcarga-text-container-two">
            <h4 className="headline-sb" style={{ fontWeight: "600" }}>
              Entregas en <span style={{ color: "#9825F2" }}>mobile</span>
            </h4>
            <p className="pre-title" style={{ paddingTop: "31px" }}>
              Monitorea cada entrega, vehículo y conductor al instante con
              nuestra app de entregas móviles. Gracias a nuestras
              actualizaciones en tiempo real, el tiempo de llegada no será una
              preocupación. Podrás estar preparado para cualquier imprevisto con
              nuestras alertas, garantizando entregas sin problemas. Además,
              integramos con Google Maps y Waze para una navegación eficiente.
              ¡Optimiza tus entregas con nuestra app!
            </p>
            <div className="pcarga-feature-item">
              <FeatureItem
                description={"Ubicación de vehículos por Trackpoints GPS."}
              />
              <FeatureItem
                description={"Aplicación móvil para reportar las paradas."}
              />
              <FeatureItem
                description={"Fotos, conformidad, razones, comentarios."}
              />
              <FeatureItem
                description={"Hora, fecha, coordenadas de entregas y reportes."}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          // marginTop: "50px",
          backgroundImage: `url(${P3})`,
          backgroundPosition: "initial",
        }}
      >
        <Col lg={12}>
          <div className="pcarga-text-container-three">
            <div className="pcarga-tree-title">
              <h4
                className="headline-sb"
                style={{
                  fontWeight: "600",
                  color: "#9825F2",
                }}
              >
                Monitorea{" "}
                <span style={{ color: "#000" }}>tus visitas en vivo</span>
              </h4>
              <p className="pre-title">
                ¡Toma el control de tu logística! Explora nuestras rutas con
                mapas y tablas de Monitoreo, visualiza la vista global del día y
                el ETA en tiempo real para cada parada!.
              </p>
            </div>
            <div className="pcarga-tree-collapse">
              <Collapse accordion items={items} />
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="pcarga-image-container-three">
            <Image src={PlannerC3} preview={false} alt="monitoreo-tiempo-real- control-de-flota-saas.gif"/>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          marginTop: "80px",
          backgroundImage: `url(${Pr4})`,
          backgroundPosition: "initial",
        }}
      >
        <Col lg={12} className="inv-col">
          <div className="pcarga-image-container-four">
            <Image
              src={PlannerC4}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="notificacion-clientes.gif"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="pcarga-text-container-four">
            <div className="container-four-title">
              <h4
                className="headline-sb"
                style={{ fontWeight: "600", color: "#9825F2" }}
              >
                Seguimiento{" "}
                <span style={{ color: "#000" }}>en tiempo real </span>
                para tus clientes
              </h4>
              <p className="pre-title">
                Ofrece a tus clientes una experiencia única con mail y
                seguimiento de pedidos. Elimina la incertidumbre al mostrar la
                ubicación exacta de sus productos y el horario estimado de
                llegada. ¡Mantén a tus clientes informados y satisfechos en todo
                momento!
              </p>
            </div>
            <div className="feature-item-four">
              <FeatureItem
                description={
                  "Mail configurable con estilo corporativo del cliente."
                }
              />
              <FeatureItem
                description={
                  "Plataforma de Seguimiento en Línea con Eventos. Con cada envío, tus clientes recibirán un enlace de seguimiento con eventos como pedido planificado, pedido en ruta, entrega exitosa/fallida/parcial del pedido, lo que les permitirá estar al tanto de su pedido en tiempo real."
                }
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="row-banner">
        <Col lg={14}>
          <div className="banner-pla">
            <h3 className="headline">
              ¡Convierte tu{" "}
              <span style={{ color: "#9825F2" }}>logística de</span>
            </h3>
            <h3 className="headline" style={{ color: "#9825F2" }}>
              carga
              <span style={{ color: "#161227" }}>
                {" "}
                en una fuerza imparable!
              </span>
            </h3>
            <div className="banner-button-pla">
              <Button
                className="buttonPrimary"
                style={{ marginTop: "71px" }}
                onClick={scrollToForm}
              >
                Lo quiero
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={10}>
          <div className="banner-image-pla">
            <Image src={PlannerC5} preview={false} />
          </div>
        </Col>
      </Row>
      <div ref={formRef} />
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${FormImg})`,
        }}
        justify={"center"}
      >
        <div className="form-container-pri">
          <PlannersForm title={"Planners carga"} />
        </div>
      </Row>
    </Content>
  );
};

export default KitPlannersCarga;
