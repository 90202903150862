import { useEffect, useState } from "react";
import { Button, Col, Row, Typography, Image, Carousel } from "antd";
import { Content } from "antd/es/layout/layout";
import LatamIcon from "../../assets/img/Latam_Logo.png";
import CopevalIcon from "../../assets/img/Copeval_Logo.png";
import SkyIcon from "../../assets/img/Sky_Logo.png";
import CopecIcon from "../../assets/img/Copec_Logo.png";
import CruzVerdeIcon from "../../assets/img/CruzVerde_Logo.png";
import HomeGif from "../../assets/img/home_principal_2.gif";
import homeImg2 from "../../assets/img/Frame2277.png";
import PCarga from "../../assets/img/monitoreo-tiempo-real-control-de-flota.gif";
import HomePassengers from "../../assets/img/Home_3.png";
import HomeLogistics from "../../assets/img/Home_4.png";
import HomePricing from "../../assets/img/Home_6.png";
import HPricing from "../../assets/img/PRICING.gif";
import DictucIcon from "../../assets/img/DICTUC_PARTNETR.png";
import ActrackIcon from "../../assets/img/LogoAcmoTrack 1.png";
import ShiftIcon from "../../assets/img/shift_PARTNER.png";
import MaxilodaIcon from "../../assets/img/Maxiloda_Stacking_Solutions_logo 1.png";
import Principal from "../../assets/img/Principal.png";
import HLogistics from "../../assets/img/Home_Logistics.png";
import HInventory from "../../assets/img/modelo-predictivo-quiebre-inventario.gif";
import RecLat from "../../assets/img/rec_lat.png";
import RecEsach from "../../assets/img/rec_esach.png";
import RecTerpel from "../../assets/img/rec_terpel.png";
import RecDerco from "../../assets/img/rec_derco.png";
import RecInter from "../../assets/img/rec_intercarry.png";
import RecCop from "../../assets/img/rec_copeval.png";
import PP2 from "../../assets/img/monitoreo-gestión-tiemporal-pasajeros.gif";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGears,
  faMagnifyingGlassLocation,
  faComments,
  faChartLine,
  faDiagramProject,
  faHandHoldingDollar,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";

import FeatureCard from "../../components/FeatureCard/FeatureCard";

import TestimonialCard from "../../components/TestimonialCard/TestimonialCard";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../components/WhatsappIcon/WhatsappIcon";
import { useMediaQuery } from "react-responsive";

const { Text } = Typography;

const featureData = [
  {
    icon: faGears,
    title: "Optimiza tus rutas",
    description:
      "Ahorra tiempo y costos con nuestra plataforma y mejora la experiencia de tus clientes",
  },
  {
    icon: faMagnifyingGlassLocation,
    title: "Monitorea en tiempo real",
    description:
      "Haz el seguimiento de tu flota en tiempo real y conoce el estado de las entregas de manera rápida, fácil y confiable.",
  },
  {
    icon: faComments,
    title: "Consultoría Logística",
    description:
      "¿Flota correcta? ¿Calendarización eficiente? ¿Diseño de layouts? Somos un aliado para mejorar tu operación.",
  },
  {
    icon: faChartLine,
    title: "Gestiona tus compras e inventarios",
    description:
      "Define el inventario apropiado en cada punto de venta para no dejar clientes insatisfechos ni generar pérdidas por sobrestock",
  },
  {
    icon: faDiagramProject,
    title: "Despreocúpate del Forecast",
    description:
      "Mejorar la precisión de tus decisiones utilizando pronósticos de alta tecnología y capacidad predictiva.",
  },
  {
    icon: faHandHoldingDollar,
    title: "Pricing Detallado",
    description:
      "Definir el precio óptimo de cada producto en cada punto de venta para aumentar la rentabilidad, en pocos clicks.",
  },
];

const testimonialData = [
  {
    quote:
      "“Son un socio estratégico en términos de mejora continua de procesos. Hemos logrado un ahorro del 18% versus el gasto histórico”",
    author: "Gonzalo Díaz",
    role: "Subgerente Movilización Latam Airlines",
    image: RecLat,
  },
  {
    quote:
      "“Con Routing conectamos todos nuestros procesos operacionales y logramos mejorar un 20% la puntualidad”",
    author: "Alberto Murillo",
    role: "Gerente Operacionales Esachs",
    image: RecEsach,
  },
  {
    quote:
      '"Con el servicio de inventarios de ROUTING podemos mirar el inventario de una manera que nos permite prepararnos mejor para el futuro y tener más claridad del status actual. Con esto, hemos logrado aumentar la venta un 12% a la vez que bajamos la inversión necesaria en stock un 3%"',
    author: "José Hernández Segura",
    role: "Consultor de negocios Terpel Colombia",
    image: RecTerpel,
  },
  {
    quote:
      "“¡Muy profesionales! Flexibles y abiertos a explorar nuevas alternativas que se ajusten a la necesidad de su cliente, siempre en la búsqueda de obtener el mejor resultado posible para alcanzar los objetivos propuestos, logrando una gran experiencia de trabajo”.",
    author: "Tomás Lorca Cammas",
    role: "Jefe Inteligencia de Negocios / Derco",
    image: RecDerco,
  },
  {
    quote:
      "“Implementamos un sistema de planificación, monitoreo y control en tiempo real que nos ha dado visibilidad y capacidad de gestionar a los transportistas y procesos internos, logrando así generar eficiencias operacionales y mejoras en nuestro nivel de servicio.”",
    author: "Javier Piña",
    role: "Gerente de transportes de Intercarry",
    image: RecInter,
  },
  {
    quote:
      "“Respecto a la plataforma operacional que utilizamos día a día, podemos destacar la capacidad de la cartografía que utilizamos para llegar a los campos de todos nuestros clientes, lo que luego se valida con los reportes de nuestros conductores. Esto también nos ha llevado a aumentar el nivel de servicio de cara a nuestros clientes, ya que podemos planificar y notificar la hora de llegada de los pedidos a destino. ”",
    author: "Sebastián Muñoz",
    role: "Gerente Cadena de Suministros Copeval",
    image: RecCop,
  },
];
const Home = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isHd = useMediaQuery({ minWidth: 768, maxWidth: 1367 });

  useEffect(() => {
    if (isMobile) {
      setSlidesToShow(1);
    } else if (isHd) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(3);
    }
  }, [isMobile, isHd]);

  return (
    <Content style={{ paddingTop: "103px", paddingBottom: "75px" }}>
      <Row
        className="row-with-background htitle"
        style={{ backgroundImage: `url(${Principal})` }}
      >
        <Col xs={24} lg={12}>
          <div className="text-container">
            <h3 className="headline">
              Las mejores soluciones logísticas end-to-end en un solo lugar
            </h3>

            <h2 className="subtitle" style={{ paddingTop: "35px" }}>
              Te acompañamos en cada etapa de tu operación logística para que
              juntos logremos la mejor optimización
            </h2>
            <div className="button-container">
              <Link to="/contacto">
                <Button className="buttonPrimary">Habla con un experto</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="image-container">
            <Image preview={false} src={HomeGif} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div>
            <Link to="/contacto" className="contact-container">
              <Button className="buttonPrimary">Habla con un experto</Button>
            </Link>
            <WhatsappIcon />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "111px" }}>
        <Col xs={24} lg={8}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="text-container-two">
              <h4 className="headline-sb" style={{ color: "#9825F2" }}>
                + de 100{" "}
                <span className="headline-sb" style={{ color: "#717171" }}>
                  empresas
                </span>
              </h4>
              <h4 className="headline-sb" style={{ color: "#717171" }}>
                confían en nosotros
              </h4>
            </div>
            <div
              style={{ width: "1px", height: "109px", background: "#D9D9D9" }}
            />
          </div>
        </Col>
        <Col xs={24} lg={16} style={{ display: "flex", alignItems: "center" }}>
          <div className="companies-container">
            <Image src={LatamIcon} preview={false} />
            <Image src={CopevalIcon} preview={false} />
            <Image src={SkyIcon} preview={false} />
            <Image src={CopecIcon} preview={false} />
            <Image src={CruzVerdeIcon} preview={false} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={24}>
          <div className="feature-title">
            <h3 className="headline">
              ¿Qué podrás hacer con
              <span style={{ color: "#9825F2" }}> The Optimal (TO)</span>?
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "45px",
              }}
            ></div>
          </div>
        </Col>
        <Col xs={24} lg={24}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "26px",
              paddingTop: "124px",
              paddingBottom: "121px",
            }}
          >
            {featureData.map((feature, index) => (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row
        style={{
          background: "#FAF9FF",
        }}
      >
        <Col xs={24} lg={14}>
          <div className="home-banner-title">
            <h3 className="headline">Descubre la Excelencia Logística</h3>
            <h3 className="headline">
              en un solo destino:
              <span style={{ color: "#9825F2" }}> Soluciones </span>
            </h3>
            <h3 className="headline" style={{ color: "#9825F2" }}>
              end-to-end de alto nivel
            </h3>
            <div className="home-button-banner">
              <Link to="/contacto">
                <Button className="buttonPrimary">Habla con un asesor</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col lg={10}>
          <div className="home-banner">
            <Image src={homeImg2} preview={false} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <div className="home-items-container">
            <Text className="headline">
              ¡Ahorra tiempo con una{" "}
              <Text
                className="headline"
                style={{
                  color: "#9825F2",
                }}
              >
                planificación y optimización eficaz
              </Text>
              !
            </Text>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "45px",
              }}
            ></div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${HomeLogistics})`,
          backgroundPosition: "top",
        }}
      >
        <Col xs={24} lg={12}>
          <div className="pcarga-container">
            <h4 className="headline-sb">
              Kit<span style={{ color: "#9825F2" }}> Planners Carga</span>
            </h4>
            <div style={{ paddingTop: "23px" }}>
              <p className="pre-title">
                Optimiza tu logística de carga con la mejor plataforma SaaS TMS
                de planificación de rutas.
              </p>
              <p className="pre-title">
                Trazabilidad y monitoreo de tus pedidos en todo momento.
              </p>
            </div>
            <div style={{ paddingTop: "29px" }}>
              <Link to="/software/kit-planners-carga">
                <Button className="button-secondary">Saber más</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="pcarga-image">
            <Image
              src={PCarga}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="monitoreo-tiempo-real-control-de-flota.gif"
            />
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background pp-image"
        style={{
          backgroundImage: `url(${HomePassengers})`,
          backgroundPosition: "center",
        }}
      >
        <Col xs={24} lg={12} className="inv-col">
          <div className="container-img-left">
            <Image
              src={PP2}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="monitoreo-gestión-tiemporal-pasajeros.gif"
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="pp-text">
            <h4 className="headline-sb">
              Kit<span style={{ color: "#9825F2" }}> Planners pasajeros</span>
            </h4>
            <div style={{ paddingTop: "23px" }}>
              <p className="pre-title">
                Descubre nuestra plataforma especializada en transporte de
                pasajeros. Planifica, monitorea y notifica rutas. ¡Mejora la
                experiencia de transporte con nosotros!
              </p>
            </div>
            <div style={{ paddingTop: "29px" }}>
              <Link to="/software/kit-planners-pasajeros">
                <Button className="button-secondary">Saber más</Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${HomeLogistics})`,
          backgroundPosition: "top",
        }}
      >
        <Col xs={24} lg={12}>
          <div className="log-container">
            <h4 className="headline-sb" style={{ color: "#9825F2" }}>
              Consultoría logística
            </h4>
            <div style={{ paddingTop: "23px" }}>
              <p className="pre-title">
                Diagnóstico y consultoría expertos: Soluciones a la altura de
                tus necesidades y requerimientos.
              </p>
            </div>
            <div style={{ paddingTop: "29px" }}>
              <Link to="/asesorias/consultoria-logistica">
                <Button className="button-secondary">Saber más</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="log-image">
            <Image src={HLogistics} preview={false} />
          </div>
        </Col>
      </Row>
      <Row className="row-with-background">
        <Col xs={24} lg={12} className="inv-col">
          <div className="container-img-left">
            <Image
              src={HInventory}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
              alt="modelo-predictivo-quiebre-inventario.gif"
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="container-text-right">
            <h4 className="headline-sb" style={{ color: "#9825F2" }}>
              Inventory
            </h4>
            <div style={{ paddingTop: "23px" }}>
              <p className="pre-title">
                Planeación de inventarios eficiente: Equilibra demanda e
                inversión con precisión en cada punto de venta o centro de
                distribución.
              </p>
            </div>
            <div style={{ paddingTop: "29px" }}>
              <Link to="/software/inventory">
                <Button className="button-secondary">Saber más</Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background pri-row"
        style={{
          backgroundImage: `url(${HomePricing})`,
          backgroundPosition: "top",
        }}
      >
        <Col xs={24} lg={12}>
          <div className="pri-container">
            <h4 className="headline-sb" style={{ color: "#9825F2" }}>
              Pricing
            </h4>
            <div style={{ paddingTop: "23px" }}>
              <p className="pre-title">
                Potente gestión de precios: Define, evalúa y maximiza tus
                estrategias de negocio de forma eficiente. ¡Optimiza tus
                decisiones y beneficios con nuestra plataforma!.
              </p>
            </div>
            <div style={{ paddingTop: "29px" }}>
              <Link to="/software/pricing">
                <Button className="button-secondary">Saber más</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="pri-image">
            <Image
              src={HPricing}
              preview={false}
              style={{ borderRadius: "20px", border: "1px solid #D9D9D9" }}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "111px" }}>
      {/* <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25358222' frameborder='0' width='100%' height='750'></iframe> */}
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col
          xs={24}
          lg={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="text-container-two"
            style={{ display: "flex", alignItems: "center", gap: "44px" }}
          >
            <h4 className="headline-sb" style={{ color: "#6638B3" }}>
              Nuestros Partners
            </h4>
            <div className="line-partners" />
          </div>
        </Col>
        <Col xs={24} lg={18}>
          <div className="companies-container">
            <Image src={DictucIcon} preview={false} />
            <Image src={ActrackIcon} preview={false} />
            <Image src={ShiftIcon} preview={false} />
            <Image src={MaxilodaIcon} preview={false} />
          </div>
        </Col>
      </Row>
      <Row style={{ background: "#F9F4FF", marginTop: "145px" }}>
        <Col xs={24} lg={24}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              paddingTop: "67px",
            }}
          >
            <FontAwesomeIcon
              icon={faQuoteRight}
              size="2xl"
              style={{ color: "#9825f2", height: "58px" }}
            />
            <h3 className="headline">¿Qué dicen nuestros clientes?</h3>
          </div>
        </Col>
        <Col xs={24} lg={24}>
          <div style={{ padding: "0 30px 0 30px" }}>
            <Carousel
              dotPosition="button"
              style={{ paddingBottom: "122px", paddingTop: "77px" }}
              slidesToShow={slidesToShow}
              infinite={false}
            >
              {testimonialData.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  quote={testimonial.quote}
                  author={testimonial.author}
                  position={testimonial.role}
                  image={testimonial.image}
                />
              ))}
            </Carousel>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default Home;
