import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Image,
  Button,
  Card,
  Carousel,
  Breadcrumb,
} from "antd";
import Principal from "../../assets/img/nosotros/Nosotros_principal.png";
import NosotrosGif from "../../assets/img/nosotros/Nosotros_principal.gif";
import N1 from "../../assets/img/nosotros/the-optimal-equipo-joven-cultura.png";
import N2 from "../../assets/img/nosotros/the-optimal-cercania-logros.png";
import N3 from "../../assets/img/nosotros/the-optimal-confianza-calidez.png";
import P4 from "../../assets/img/Home_4.png";
import Service from "../../assets/img/nosotros/services_team.png";
import Modeling from "../../assets/img/nosotros/modeling_team.png";
import Central from "../../assets/img/nosotros/central_team.png";
import Tech from "../../assets/img/nosotros/tech_team.png";
import Onboarding from "../../assets/img/nosotros/onboarding_team.png";
import FeatureItem from "../../components/FeatureItem/FeatureItem";
import AboutUsCard from "../../components/AboutUsCard/AboutUsCard";
import { useMediaQuery } from "react-responsive";
import ReactPlayer from "react-player";
import Video from "../../assets/video/the-optimal-nosotros.mp4";
import { Link } from "react-router-dom";

const { Content } = Layout;

const Nosotros = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [showVideo, setShowVideo] = useState(true);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isHd = useMediaQuery({ minWidth: 768, maxWidth: 1367 });

  const glink = "https://www.getonbrd.com/companies/routinguc";

  useEffect(() => {
    if (isMobile) {
      console.log(isMobile, isHd);
      setSlidesToShow(1);
      setShowVideo(false);
    } else if (isHd) {
      setSlidesToShow(2);
      setShowVideo(true);
    } else {
      setSlidesToShow(3);
    }
  }, [isMobile, isHd]);
  const aboutUsData = [
    {
      icon: "noto:purple-heart",
      title: "EMPÁTICO",
      description:
        "Sabemos que el éxito se logra unidos, construyendo juntos un futuro exitoso con base en el respeto y colaboración.",
    },
    {
      icon: "noto:victory-hand-medium-light-skin-tone",
      title: "POSITIVO",
      description:
        "La actitud es clave para cada desafío. Nuestra energía y pasión en lo que hacemos, es nuestro motor para resultados sobresalientes.",
    },
    {
      icon: "noto:handshake-light-skin-tone-medium-skin-tone",
      title: "COMPROMETIDO",
      description:
        "Responsabilidad, creatividad, compromiso y perseverancia, son fundamentales para entregar resultados excepcionales en cada servicio brindado.",
    },
    {
      icon: "noto:smiling-face-with-open-hands",
      title: "CERCANO",
      description:
        "Nuestra unión es nuestra fortaleza. Abrazamos los retos con determinación, transformando problemas en oportunidades. ¡Juntos somos imparables!",
    },
    {
      icon: "noto:test-tube",
      title: "INNOVADOR",
      description:
        "Buscamos innovar en todos los desafíos. Cada nueva tecnología es una oportunidad para avanzar hacia la excelencia, superando todos los límites.",
    },
    {
      icon: "noto:thinking-face",
      title: "ANALÍTICO",
      description:
        "Analíticos por naturaleza y siempre en busca de nuevos conocimientos. El aprendizaje continuo es clave para crecer y alcanzar el éxito con resultados excepcionales.",
    },
  ];

  return (
    <Content style={{ paddingTop: "103px" }}>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${Principal})`,
          backgroundSize: "contain",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          textAlign: "center",
        }}
      >
        <Col span={20} offset={2}>
          <Breadcrumb
          style={{marginTop: '46px'}}
            items={[
              {
                title: "Nosotros",
              },
              {
                title: "Trabaja con nosotros",
              },
            ]}
          />
        </Col>
        <Col lg={24}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="n-main-title">
              <h3
                className="headline"
                style={{
                  color: "#9825F2",
                }}
              >
                Más de 25 años <span className="headline"> de experiencia</span>
              </h3>
              <h3 className="headline">juntos hacia el éxito</h3>
            </div>
            <div style={{ paddingTop: "53px" }}>
              <Link to={glink}>
                <Button className="button-secondary">
                  Trabaja con nosotros
                </Button>
              </Link>
            </div>
            <div className="n-image-container">
              <Image
                src={NosotrosGif}
                preview={false}
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24}>
          <div className="n-text-container">
            <p className="pre-title-r" style={{ textAlign: "center" }}>
              En The Optimal, Spin-OFF de dictuc y empresa fundada bajo el alero
              de la Pontificia Universidad Católica de Chile, creemos firmemente
              que cada desafío es una oportunidad para crecer, aprender y
              brillar. Nos apasiona la búsqueda constante de la excelencia y
              estamos comprometidos en ofrecer resultados que superen las
              expectativas. Fomentamos un ambiente colaborativo y respetuoso,
              donde cada miembro contribuye al éxito colectivo. Nuestro mayor
              impulso proviene de marcar la diferencia, focalizados en las
              necesidades y expectativas de nuestros clientes. ¡Juntos,
              desafiamos los límites!
            </p>
          </div>
        </Col>
      </Row>
      <Row
        className="row-with-background"
        style={{
          backgroundImage: `url(${P4})`,
          backgroundSize: "cover",
          backgroundPosition: "0px 6px",
          marginTop: "164px",
        }}
      >
        <Col lg={24}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              paddingTop: "70px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <h3 className="headline">
              ¿Por qué ser parte de{" "}
              <span className="headline" style={{ color: "#9825F2" }}>
                The Optimal
              </span>
              ?
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "44px",
              }}
            ></div>
          </div>
        </Col>
        <Col lg={12} className="inv-col">
          <div className="n-image-container-two">
            <Image
              src={N1}
              preview={false}
              alt="the-optimal-equipo-joven-cultura.png"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="n-feature-container">
            <FeatureItem
              description={
                "Somos un equipo jovial que valora a las personas y su bienestar, generando un sentido de pertenencia único."
              }
            />
            <FeatureItem
              description={
                "Tenemos una cultura colaborativa, donde nos apoyamos mutuamente, aprovechamos la diversidad de los talentos que nos lleva a resultados extraordinarios"
              }
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "53px" }}>
        <Col lg={12}>
          <div className="n-feature-container-two">
            <FeatureItem
              description={
                "La cercanía con la alta gerencia y el directorio es primordial, valoramos y apreciamos cada opinión, porque sabemos que todas son importantes para construir el camino al éxito."
              }
            />
            <FeatureItem
              description={
                "Contamos con excelentes instalaciones, brindamos la inspiración necesaria para alcanzar grandes logros."
              }
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="n-image-container-three">
            <Image
              src={N2}
              preview={false}
              alt="the-optimal-cercania-logros.png"
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "140px" }} className="row-with-background">
        <Col lg={12} className="inv-col">
          <div className="n-image-container-three">
            <Image
              src={N3}
              preview={false}
              alt="the-optimal-confianza-calidez.png"
            />
          </div>
        </Col>
        <Col lg={12}>
          <div className="n-feature-container-three">
            <FeatureItem
              description={
                "Creamos un ambiente de confianza, donde la calidez humana y la flexibilidad ante la contingencia son pilares fundamentales."
              }
            />
            <FeatureItem
              description={
                "Valoramos el feedback constante, promovemos la comunicación fluida"
              }
            />
          </div>
        </Col>
      </Row>
      <Row
        style={{
          background: "#FAF9FF",
          textAlign: "center",
          marginTop: "180px",
        }}
      >
        <Col lg={24}>
          <div className="about-container">
            <h3 className="headline">
              ¿Cómo es
              <span style={{ color: "#9825F2" }}> The Optimal Team</span>?
            </h3>
            <div>
              <Link to={glink}>
                <Button className="button-secondary">
                  Trabaja con nosotros
                </Button>
              </Link>
            </div>
          </div>

          <div className="about-data-container">
            {aboutUsData.map((about, index) => (
              <AboutUsCard
                key={index}
                icon={about.icon}
                title={about.title}
                description={about.description}
              />
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={24}>
          <div className="team-container">
            <h3 className="headline">
              Nuestro<span style={{ color: "#9825F2" }}> Team</span>
            </h3>
            <div
              style={{
                width: "183px",
                height: "7px",
                background: "#9825F2",
                margin: "0 auto",
                marginTop: "64px",
              }}
            ></div>
          </div>
        </Col>
        <Col lg={24}>
          <div className="n-carousel-container">
            <Carousel
              dotPosition="button"
              style={{ paddingBottom: "122px" }}
              slidesToShow={slidesToShow}
              infinite={false}
            >
              <div>
                <div className="n-carousel-item">
                  <Image src={Service} preview={false} />
                  <h3 className="team-text">Servicios Team</h3>
                </div>
              </div>
              <div>
                <div className="n-carousel-item">
                  <Image src={Modeling} preview={false} />
                  <h3 className="team-text">Modelación Team</h3>
                </div>
              </div>
              <div>
                <div className="n-carousel-item">
                  <Image src={Onboarding} preview={false} />
                  <h3 className="team-text">Onboarding Team</h3>
                </div>
              </div>
              <div>
                <div className="n-carousel-item">
                  <Image src={Tech} preview={false} />
                  <h3 className="team-text">Tecnología Team</h3>
                </div>
              </div>
              <div>
                <div className="n-carousel-item">
                  <Image src={Central} preview={false} />
                  <h3 className="team-text">Central Team</h3>
                </div>
              </div>
            </Carousel>
          </div>
          <div
            style={{
              textAlign: "center",
              paddingTop: "40px",
              paddingBottom: "92px",
            }}
          >
            <Button className="button-secondary" style={{ display: "none" }}>
              Trabaja con nosotros
            </Button>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default Nosotros;
