import React from "react";
import { Layout, Row, Col } from "antd";

const { Content } = Layout;

const PrivacyPolicies = () => {
  return (
    <Content className="term-container">
      <Row>
        <Col xs={24} lg={24}>
          <div style={{ textAlign: "center" }}>
            <h3 className="headline">
              Políticas de
              <span
                className="headline"
                style={{
                  color: "#9825F2",
                }}
              >
                {" "}
                privacidad
              </span>
            </h3>
            <span className="headline-sb">
              (Aplicaciones móviles The Optimal)
            </span>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "126px" }}>
        <Col lg={24}>
          <h2 className="subtitle-medium">
            PRIMERO: Uso de Ubicación en Segundo Plano
          </h2>

          <ol style={{ paddingTop: "34px" }}>
            <li className="subtitle-medium" style={{ marginTop: "50px" }}>
              Recopilación de Datos de Ubicación:
              <ul
                style={{
                  listStyleType: "disc",
                  color: "black",
                  marginTop: "50px",
                }}
              >
                <li className="pre-title-r">
                  Las aplicaciones mencionadas recopilan datos de ubicación en
                  segundo plano proporcionar y mejorar los servicios de
                  ubicación de la aplicación.
                </li>
                <li className="pre-title-r">
                  Estos datos incluyen información sobre la ubicación actual del
                  dispositivo y una marca de fecha y hora, que se recopila de
                  forma periódica mientras la aplicación está en segundo plano.
                </li>
              </ul>
            </li>
            <li className="subtitle-medium" style={{ marginTop: "50px" }}>
              Uso de los Datos de Ubicación:{" "}
              <ul
                style={{
                  listStyleType: "disc",
                  color: "black",
                  marginTop: "50px",
                }}
              >
                <li className="pre-title-r">
                  Los datos de ubicación en segundo plano se utilizan
                  exclusivamente para mejorar la precisión al mostrar la
                  ubicación del usuario en mapas integrados dentro de la
                  aplicación y para notificar la posición actual del dispositivo
                  de forma constante a la plataforma web LLEGANDO y PLANNERS con
                  el objetivo de realizar el seguimiento de los vehículos
                  asociados a estos dispositivos móviles
                </li>
                <li className="pre-title-r">
                  Esto incluye funciones como notificaciones de proximidad,
                  alertas de proximidad a los puntos de visita en una ruta y el
                  seguimiento de los vehículos asociados a las rutas.
                </li>
              </ul>
            </li>
            <li className="subtitle-medium" style={{ marginTop: "50px" }}>
              Compartir Datos de Ubicación:{" "}
              <ul
                style={{
                  listStyleType: "disc",
                  color: "black",
                  marginTop: "50px",
                }}
              >
                <li className="pre-title-r">
                  Los datos de ubicación en segundo plano no se comparten con
                  terceros sin el consentimiento explícito del usuario.
                </li>
                <li className="pre-title-r">
                  No vendemos ni compartimos información de ubicación con
                  anunciantes ni con ninguna entidad externa con fines
                  comerciales.
                </li>
              </ul>
            </li>
            <li className="subtitle-medium" style={{ marginTop: "50px" }}>
              Seguridad de los Datos:{" "}
              <ul
                style={{
                  listStyleType: "disc",
                  color: "black",
                  marginTop: "50px",
                }}
              >
                <li className="pre-title-r">
                  Se implementan medidas de seguridad adecuadas para proteger
                  los datos de ubicación contra accesos no autorizados,
                  alteraciones o divulgaciones.
                </li>
                <li className="pre-title-r">
                  Los datos se almacenan de forma segura en servidores
                  protegidos y se utilizan solo según lo establecido en esta
                  política de privacidad.
                </li>
              </ul>
            </li>
            <li className="subtitle-medium" style={{ marginTop: "50px" }}>
              Consentimiento del Usuario:{" "}
              <ul
                style={{
                  listStyleType: "disc",
                  color: "black",
                  marginTop: "50px",
                }}
              >
                <li className="pre-title-r">
                  Al utilizar la aplicación, el usuario da su consentimiento
                  para la recopilación y el uso de datos de ubicación en segundo
                  plano de acuerdo con esta política de privacidad.
                </li>
              </ul>
            </li>
            <li className="subtitle-medium" style={{ marginTop: "50px" }}>
              Control de Datos:{" "}
              <ul
                style={{
                  listStyleType: "disc",
                  color: "black",
                  marginTop: "50px",
                }}
              >
                <li className="pre-title-r">
                  Los usuarios tienen la opción de desactivar el uso de
                  ubicación en segundo plano en la configuración de la
                  aplicación en cualquier momento.
                </li>
                <li className="pre-title-r">
                  Además, los usuarios pueden solicitar la eliminación de sus
                  datos de ubicación almacenados mediante una solicitud al
                  equipo de soporte de la aplicación.
                </li>
              </ul>
            </li>
          </ol>
        </Col>
      </Row>
    </Content>
  );
};

export default PrivacyPolicies;
