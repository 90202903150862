import { Form, Input, Row, Col, Select, Button, Image, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {useEffect} from "react";
import FlagCH from "../../assets/img/flag-chile.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;

const PlannersForm = ({ title }) => {
  const currentPath = location.pathname;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const form = document.getElementById("idForm");
    form.addEventListener("submit", handleFormSubmit);

    return () => {
      form.removeEventListener("submit", handleFormSubmit);
    };
    
  }, []);

  const shouldShowVehiclesInput = () => {
    return (
      !currentPath.includes("/software/inventory") &&
      !currentPath.includes("/software/pricing") &&
      !currentPath.includes("/asesorias/consultoria-logistica")
    );
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 100 }}>
        <Option value="56">
          <Image src={FlagCH} /> +56
        </Option>
      </Select>
    </Form.Item>
  );

  const sendEmail = async (values) => {
    var template_data = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <title>Formulario </title>
      </head>
      <body>
        <h2>Datos de contacto:</h2>
        
        <p><strong>Email:</strong> ${values.email}</p>
        <p><strong>Teléfono:</strong> ${values.phone}</p>
        <p><strong>Nombre:</strong> ${values.name}</p>
        <p><strong>Compañía:</strong> ${values.company}</p>
        <h2> Datos de operación y ayuda:</h2>
        <p><strong>Detalles de la operación:</strong> ${values.operationDetails}</p>
       `;

    if (values.vehicles) {
      template_data += `
      <h2> Cantidad de vehículos:</h2>
      <p><strong>Vehículos:</strong> ${values.vehicles}</p>`;
    }
    template_data += `
    </body>
  </html>
  `;
    const data = {
      from: "sender@theoptimalpartner.com",
      template: template_data,
      email_addresses: ["contacto@theoptimalpartner.com"],
      subject: "Formulario de contacto",
      attachments: [],
    };
    try {
      const response = await axios.post("https://sonic.routingmobile.com/api/v1/llegando/deliver", data, {
        headers: {
          "Content-Type": "application/json",
          'ApiKey': 'b5e4c14630bac2aeb66e7770'
        },
      });
      console.log("Email sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault()

    try{
      sendEmail(form.getFieldsValue())
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "gtm.formSubmit",
      });
  
      message.success(
        "¡Formulario enviado con éxito! Nos comunicaremos contigo en breve. Gracias por ponerte en contacto."
      );

      form.resetFields();

      navigate("/");

    } catch(error){
      message.error("Error al enviar el formulario. Por favor, inténtelo de nuevo.")
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    message.info("Complete los campos obligatorios");
  };

  return (
    <div
      style={{
        borderRadius: "10px",
        border: "1px solid #D9D9D9",
        background: "#FFF",
        padding: "20px",
        maxWidth: "711px",
      }}
    >
      <Form
        form={form}
        initialValues={{ prefix: "56" }}
        onFinish={handleFormSubmit}
        onFinishFailed={onFinishFailed}
        id="idForm"
      >
        <Row justify={"center"}>
          <Col style={{ paddingTop: "61px" }}>
            <h4 className="headline-one">
              Obtén <span style={{ color: "#9825F2" }}>{title}</span>
            </h4>
          </Col>
        </Row>
        <Row className="row-form">
          <Col lg={11}>
            <Form.Item
              name="name"
              label="Nombre completo"
              labelCol={{ span: 24 }}
            >
              <Input placeholder="nombre" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col lg={11}>
            <Form.Item
              label="Correo electrónico"
              name="email"
              rules={[
                {
                  required: true,
                  message: "¡El correo es requerido!",
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input placeholder="correo@correo.com" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="row-form">
          <Col lg={11}>
            <Form.Item
              name="company"
              label="Empresa"
              rules={[
                {
                  required: true,
                  message: "¡Empresa es requerido!",
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input placeholder="Empresa" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col lg={11}>
            <Form.Item
              label="Teléfono de contacto:"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "¡El teléfono es requerido!",
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <Input
                addonBefore={prefixSelector}
                placeholder="XXXXXXXXX"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="row-form">
          <Col lg={11}>
            {shouldShowVehiclesInput() && (
              <Form.Item
                name="vehicles"
                rules={[
                  {
                    required: true,
                    message: "¡Vehículos es requerido!",
                  },
                ]}
                label={
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <p>Cantidad de vehículos</p>
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,0.45)" }} />
                  </div>
                }
                labelCol={{ span: 24 }}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1-10">1 - 10</Select.Option>
                  <Select.Option value="11-20">11 - 20</Select.Option>
                  <Select.Option value="21-49">21 - 49</Select.Option>
                  <Select.Option value="50+">50+</Select.Option>
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row className="row-form">
          <Col lg={24}>
            <Form.Item
              name="operationDetails"
              label={
                <div className="operation-container">
                  <p>
                    Cuéntanos más sobre tu operación y en qué podemos ayudarte{" "}
                  </p>
                </div>
              }
              labelCol={{ span: 24 }}
            >
              <TextArea rows={2} placeholder="Escribe aquí" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"end"} className="row-container-button">
          <Col>
            <Form.Item>
              <Button className="buttonPrimary btn-form" htmlType="submit">
                Enviar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PlannersForm;
